export const base = `import React from "react";

import { Radio } from "antd";

export default function Radio() {
  return (
    <>
        <Radio />
        <Radio checked />
        <Radio disabled />
        <Radio checked disabled />
    </>
  );
}`;
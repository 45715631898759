export const base = `import React, { useState } from 'react';

import { Steps } from "antd";

const steps = [
  {
    title: 'Home',
  },
  {
    title: 'Label',
  },
  {
    title: 'Label',
  },
];

export default function Steps() {
  const [current] = useState(0);

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  
  return (
    <div className="w-full max-w-[360px]">
      <Steps current={current} items={items} />
    </div>
  );
} `;
import React from "react";

import { Button, Col, Form, Input, Row } from "antd";

export default function InputPage() {
  return (
    <div className="m-12">
      <Row gutter={[24, 32]} align="middle">
        <Col span={24}>
          <h1 className="text-5xl font-bold">Inputs</h1>
        </Col>

        <Col span={8}>
          <Input size="large" placeholder="Placeholder" />
        </Col>

        <Col span={8}>
          <Input placeholder="Placeholder" />
        </Col>

        <Col span={8}>
          <Input size="small" placeholder="Placeholder" />
        </Col>

        <Col span={8}>
          <Input disabled placeholder="Placeholder" />
        </Col>

        <Col span={24}>
          <div className="border"></div>
        </Col>

        <Col span={24}>
          <Form
            name="basic"
            style={{
              maxWidth: 600,
            }}
            autoComplete="off"
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" block htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

import React from "react";

import { Button, Space, message, Tabs } from "antd";
import {
  CheckCircleSolid,
  InfoCircleSolid,
  WarningCircleSolid,
  XmarkCircleSolid,
} from "iconoir-react";

import anatomy from "assets/img/documentation/message/message-anatomy.svg";
import specs from "assets/img/documentation/message/message-specs.svg";

import { base } from "./code";
import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

export default function DocumentationMessage() {
  const [messageApi, contextHolder] = message.useMessage();

  const noicon = () => {
    messageApi.open({
      content: "Snackbar with action and close affordance",
      style: {
        textAlign: "right",
      },
    });
  };

  const success = () => {
    messageApi.open({
      icon: <CheckCircleSolid width={24} height={24} className="text-white" />,
      content: "Snackbar with action and close affordance",
      style: {
        textAlign: "right",
      },
    });
  };

  const error = () => {
    messageApi.open({
      icon: <XmarkCircleSolid width={24} height={24} className="text-white" />,
      content: "Snackbar with action and close affordance",
      style: {
        textAlign: "right",
      },
    });
  };

  const warning = () => {
    messageApi.open({
      icon: (
        <WarningCircleSolid width={24} height={24} className="text-white" />
      ),
      content: "Snackbar with action and close affordance",
      style: {
        textAlign: "right",
      },
    });
  };

  const info = () => {
    messageApi.open({
      icon: <InfoCircleSolid width={24} height={24} className="text-white" />,
      content: "Snackbar with action and close affordance",
      style: {
        textAlign: "right",
      },
    });
  };

  const loading = () => {
    messageApi.open({
      type: "loading",
      content: "Snackbar with action and close affordance",
      style: {
        textAlign: "right",
      },
    });
  };

  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <>
                {contextHolder}
                <Space>
                  <Button type="primary" onClick={noicon}>
                    Default
                  </Button>
                  <Button type="primary" onClick={success}>
                    Success
                  </Button>
                  <Button type="primary" onClick={error}>
                    Error
                  </Button>
                  <Button type="primary" onClick={warning}>
                    Warning
                  </Button>
                  <Button type="primary" onClick={info}>
                    Info
                  </Button>
                  <Button type="primary" onClick={loading}>
                    Loading
                  </Button>
                </Space>
              </>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={
              <div className="bg-gray-50 rounded-lg">
                <img src={anatomy} alt="Modal" />
              </div>
            }
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={specs}
            title="Label Only"
            column={true}
            list={[
              {
                text: "Direction",
                value: "Vertical",
              },
              {
                text: "Alignment",
                value: "Middle left",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Fixed",
              },
              {
                text: "Padding",
                value: "16",
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

import React, { useState } from "react";

import { Tabs, Slider } from "antd";
import { Heart, HeartSolid } from "iconoir-react";

import { base, handle } from "./code";

import sliderAnatomyImg from "assets/img/documentation/slider/slider-anatomy.png";
import sliderSpecsImg from "assets/img/documentation/slider/slider-specs.png";

import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

const IconSlider = (props) => {
  const { max, min } = props;
  const [value, setValue] = useState(0);
  const preColorCls = "ant-heart";
  const nextColorCls = "ant-heart-solid";

  return (
    <div className="flex items-center justify-center gap-4">
      <Heart className={preColorCls} />
      <Slider defaultValue={50} {...props} onChange={setValue} />
      <HeartSolid className={nextColorCls} />
    </div>
  );
};

export default function DocumentationSlider() {
  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            column={true}
            content={
              <div className="w-full my-auto h-full flex flex-col items-center gap-10">
                <IconSlider min={0} max={100} className="w-[19rem]" />

                <Slider
                  className="w-96"
                  range={{
                    draggableTrack: true,
                  }}
                  defaultValue={[20, 70]}
                />

                <Slider min={0} max={100} defaultValue={50} className="w-96" />
              </div>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={<img src={sliderAnatomyImg} alt="Anatomy"></img>}
          />

          <DocumentationCard
            code={handle}
            title="Handle"
            content={
              <div className="flex gap-8">
                <Slider value={100} />
                <Slider disabled />
              </div>
            }
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={sliderSpecsImg}
            column={true}
            title="Basic"
            list={[
              {
                text: "Direction",
                value: "Horizontal",
              },
              {
                text: "Alignment",
                value: "Top Left",
              },
              {
                text: "Vertical resizing",
                value: "Fixed",
              },
              {
                text: "Horizontal resizing",
                value: "Fixed",
              },
              {
                text: "Item Spacing",
                value: 8,
              },
              {
                text: "Padding left",
                value: 16,
              },
              {
                text: "Padding right",
                value: 16,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

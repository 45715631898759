import React from "react";

import { Tabs, TimePicker } from "antd";
import { NavArrowDown } from "iconoir-react";

import { base } from "./code";

import timePickerAnatomyImg from "assets/img/documentation/timepicker/timepicker-anatomy.png";
import timePickerSpecsImg from "assets/img/documentation/timepicker/timepicker-specs.png";

import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

const format = "HH:mm";

export default function DocumentationTimePicker() {
  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <div className="flex flex-col gap-2 w-40">
                <div className="text-p-sm font-medium text-primaryA">Time</div>
                <TimePicker
                  className="text-gray-600"
                  format={format}
                  placeholder="00:00 AM"
                  suffixIcon={<NavArrowDown className="text-gray-600" />}
                />
                <span className="text-p-sm text-[#5E5E5E]">Hint</span>
              </div>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={<img src={timePickerAnatomyImg} alt="Anatomy"></img>}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={timePickerSpecsImg}
            column={true}
            title="Time"
            list={[
              {
                text: "Direction",
                value: "Vertical",
              },
              {
                text: "Alignment",
                value: "Top Left",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Fixed",
              },
              {
                text: "Item Spacing",
                value: 8,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

import React from "react";

import { Tabs, DatePicker } from "antd";

import { base } from "./code";

import datePickerAnatomyImg from "assets/img/documentation/datepicker/datepicker-anatomy.png";
import datePickerSpecsImg from "assets/img/documentation/datepicker/datepicker-specs.png";

import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";
import { Calendar, NavArrowDown } from "iconoir-react";

const format = "MM/DD/YYYY";

export default function DocumentationDatePicker() {
  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <div className="flex gap-2 items-end">
                <div>
                  <span className="block text-p-xs text-primaryA font-medium mb-1">
                    Start Date
                  </span>
                  <DatePicker placeholder={format} format={format} suffixIcon={false} />
                </div>

                <div>
                  <span className="block text-p-xs text-primaryA font-medium mb-1">
                    End Date
                  </span>
                  <DatePicker placeholder={format} format={format} suffixIcon={false} />
                </div>

                <div className="relative">
                  <Calendar
                    width={18}
                    height={18}
                    strokeWidth={2}
                    className="text-gray-400 absolute top-2/4 -translate-y-2/4 left-4 z-10"
                  />
                  <DatePicker
                    className="w-[80px] date-picker-select"
                    format={format}
                    placeholder={false}
                    suffixIcon={
                      <NavArrowDown
                        width={18}
                        height={18}
                        strokeWidth={2}
                        className="text-gray-400"
                      />
                    }
                  />
                </div>
              </div>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={<img src={datePickerAnatomyImg} alt="Anatomy"></img>}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={datePickerSpecsImg}
            column={true}
            title="Small"
            list={[
              {
                text: "Direction",
                value: "Horizontal",
              },
              {
                text: "Alignment",
                value: "Top Left",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Hug",
              },
              {
                text: "Item Spacing",
                value: 8,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

export const base = `import React from "react";

import { Tag, Space } from "antd";

export default function Tag() {
  return (
    <Space size={[10]} wrap>
      <Tag>Label</Tag>
      <Tag color="red">Label</Tag>
      <Tag color="orange">Label</Tag>
      <Tag color="gold">Label</Tag>
      <Tag color="green">Label</Tag>
      <Tag color="blue">Label</Tag>
      <Tag color="purple">Label</Tag>
      <Tag color="magenta">Label</Tag>
    </Space>
  );
}`;

export const colors = `import React from "react";

import { Tag } from "antd";
import { Heart } from "iconoir-react";

export default function Tag() {
  return (
    <div className="w-full py-20 px-12 flex justify-center gap-12">
      <div>
        <div className="flex flex-col items-start gap-12">
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
          >
            Primary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            color="red"
          >
            Primary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            color="orange"
          >
            Primary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            color="gold"
          >
            Primary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            color="green"
          >
            Primary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            color="blue"
          >
            Primary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            color="purple"
          >
            Primary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            color="magenta"
          >
            Primary
          </Tag>
        </div>
      </div>

      <div>
        <div className="flex flex-col items-start gap-12">
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            className="bg-gray-500 text-white"
          >
            Secondary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            className="bg-negative text-white"
          >
            Secondary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            className="bg-orange-600 text-white"
          >
            Secondary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            className="bg-yellow-500 text-white"
          >
            Secondary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            className="bg-green-600 text-white"
          >
            Secondary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            className="bg-blue-600 text-white"
          >
            Secondary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            className="bg-purple-600 text-white"
          >
            Secondary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            className="bg-magenta-600 text-white"
          >
            Secondary
          </Tag>
        </div>
      </div>

      <div>
        <div className="flex flex-col items-start gap-12">
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            className="bg-transparent text-gray-500"
          >
            Tertiary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            className="bg-transparent text-negative"
          >
            Tertiary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            className="bg-transparent text-orange-600"
          >
            Tertiary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            className="bg-transparent text-yellow-500"
          >
            Tertiary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            className="bg-transparent text-green-600"
          >
            Tertiary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            className="bg-transparent text-blue-600"
          >
            Tertiary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            className="bg-transparent text-purple-600"
          >
            Tertiary
          </Tag>
          <Tag
            icon={<Heart width={16} height={16} strokeWidth={2} />}
            className="bg-transparent text-magenta-600"
          >
            Tertiary
          </Tag>
        </div>
      </div>
    </div>
  );
}`;
import { Carousel } from "antd";

import videoImg from "assets/img/video-img.svg";
import cardDots from "assets/img/card-dots.svg";
import dashboardImg from "assets/img/dashboard-card-img.png";

import CardItemSection from "components/CardItemSection";
import DashboardCard from "components/DashboardCard";
import { PlaySolid } from "iconoir-react";

function App() {
  return (
    <div className="flex flex-col gap-16 md:gap-24">
      <div className="-mx-3">
        <Carousel
          slidesToShow={2}
          responsive={[
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          <div className="px-3">
            <DashboardCard
              url="/vendor-detail"
              img={dashboardImg}
              date="2 EKİM, 2023"
              title="Daha iyi finansal kontrol için gider yönetimi yazılımı!"
              desc="CFO'ların ve finans ekiplerinin şirket bütçelerini ve giderlerini takip edip optimize etmelerine yardımcı olan hepsi bir arada gider yönetimi yazılımı."
              author="Flux Financial"
            />
          </div>
          <div className="px-3">
            <DashboardCard
              url="/vendor-detail"
              img={dashboardImg}
              date="2 EKİM, 2023"
              title="Daha iyi finansal kontrol için gider yönetimi yazılımı!"
              desc="CFO'ların ve finans ekiplerinin şirket bütçelerini ve giderlerini takip edip optimize etmelerine yardımcı olan hepsi bir arada gider yönetimi yazılımı."
              author="Flux Financial"
            />
          </div>
          <div className="px-3">
            <DashboardCard
              url="/vendor-detail"
              img={dashboardImg}
              date="2 EKİM, 2023"
              title="Daha iyi finansal kontrol için gider yönetimi yazılımı!"
              desc="CFO'ların ve finans ekiplerinin şirket bütçelerini ve giderlerini takip edip optimize etmelerine yardımcı olan hepsi bir arada gider yönetimi yazılımı."
              author="Flux Financial"
            />
          </div>
          <div className="px-3">
            <DashboardCard
              url="/vendor-detail"
              img={dashboardImg}
              date="2 EKİM, 2023"
              title="Daha iyi finansal kontrol için gider yönetimi yazılımı!"
              desc="CFO'ların ve finans ekiplerinin şirket bütçelerini ve giderlerini takip edip optimize etmelerine yardımcı olan hepsi bir arada gider yönetimi yazılımı."
              author="Flux Financial"
            />
          </div>
        </Carousel>
      </div>

      <CardItemSection title="Yeni ve Dikkate Değer" />

      <CardItemSection title="Bankacılık ve Finans" />

      <div
        className="md:px-12 pt-8 md:pt-12 bg-gray-50 rounded-2xl bg-cover bg-center bg-no-repeat text-center"
        style={{ backgroundImage: "url(" + cardDots + ")" }}
      >
        <span className="bg-blue-100 text-blue-600 text-p-xs md:text-p-md rounded-full px-2 md:px-3 py-1 md:py-2">
          Yapay Zeka ile Müşteri Memnuniyeti
        </span>
        <h3 className="text-primaryA text-p-lg md:text-h4 font-medium mt-3 mx-2 md:mx-12 lg:mx-36">
          Sürdürülebilir müşteri memnuniyeti ancak müşterilerinizi gerçekten
          zamanında ve doğru bir şekilde anlamanız durumunda mümkündür.
        </h3>

        <div className="relative border-4 border-gray-100 rounded-t-[32px] bg-white max-w-[850px] mx-auto border-b-0 mt-14">
          <div className="w-12 h-12 md:w-16 md:h-16 bg-gray-100 rounded-full absolute top-0 left-1/2 translate-x-[-50%] translate-y-[-50%] flex items-center justify-center">
            <PlaySolid width={32} height={32} className="text-blue-900" />
          </div>

          <img
            src={videoImg}
            alt="Yapay Zeka ile Müşteri Memnuniyeti"
            className="rounded-t-[32px] mx-auto"
          />
        </div>
      </div>

      <CardItemSection title="Bankacılık ve Finans" />
    </div>
  );
}

export default App;

import React from "react";

import { useNavigate } from "react-router-dom";

import logo from "assets/img/logo.svg";
import errorIcon from "assets/img/error-404.png";
import errorFooterLogo from "assets/img/error-footer-logo.svg";
import errorFooterLine from "assets/img/error-footer-line.svg";

import { Link } from "react-router-dom";
import { Button } from "antd";
import { ArrowLeft, Copyright } from "iconoir-react";

export default function Error404() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="h-screen flex flex-col justify-between">
        <header className="relative z-10 px-4 lg:px-12 py-3 md:py-5 border-b border-gray-100">
          <Link to={"/"} className="flex items-center gap-2 md:gap-4">
            <img src={logo} alt="Logo" className="w-10 md:w-auto" />
          </Link>
        </header>

        <div className="flex flex-col items-center justify-center text-center">
          <img src={errorIcon} alt="404 Error" className="mb-3" />
          <h1 className="text-h5 text-primaryA mb-3">404</h1>
          <p className="text-p-md mb-6 text-gray-600">
            Sayfa bulunamadı. Üzgünüz, aradığınız sayfa bulunamıyor.
          </p>

          <Button
            onClick={handleGoBack}
            size="large"
            icon={<ArrowLeft className="mr-1" width={20} height={20} />}
          >
            Geri Dön
          </Button>
        </div>

        <footer className="flex items-center justify-center py-4 gap-2">
          <Link to={"/"} className="flex items-center gap-2 md:gap-4">
            <img
              src={errorFooterLogo}
              alt="Footer Logo"
              className="w-10 md:w-auto"
            />
          </Link>

          <img
            src={errorFooterLine}
            alt="Footer Line"
            className="bg-gray-400"
          />

          <Copyright
            width={16}
            height={16}
            className="text-gray-400"
          ></Copyright>

          <span className="text-p-sm text-gray-400">
            2024 Tüm hakları saklıdır.
          </span>
        </footer>
      </div>
    </>
  );
}

export const base = `import React from "react";

import { Alert, Space } from "antd";
import {
  CheckCircleSolid,
  InfoCircleSolid,
  WarningTriangleSolid,
  XmarkCircleSolid,
} from "iconoir-react";

export default function Alert() {
  return (
    <Space
      direction="vertical"
      style={{
        maxWidth: 375,
        width: "100%",
      }}
      className="py-20"
    >
      <Alert
        icon={<WarningTriangleSolid width={24} height={24} />}
        message="Headline text"
        description="Paragraph text"
        type="warning"
        showIcon
      />
      <Alert
        icon={<InfoCircleSolid width={24} height={24} />}
        message="Headline text"
        description="Paragraph text"
        type="info"
        showIcon
      />
      <Alert
        icon={<CheckCircleSolid width={24} height={24} />}
        message="Headline text"
        description="Paragraph text"
        type="success"
        showIcon
      />
      <Alert
        icon={<XmarkCircleSolid width={24} height={24} />}
        message="Headline text"
        description="Paragraph text"
        type="error"
        showIcon
      />
    </Space>
  );
}`;
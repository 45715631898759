import { Link } from "react-router-dom";

import { Button } from "antd";

import cardIcon from "assets/img/card-icon.svg";

function CardItem({
  imgColor,
  subTitle,
  subTitleColor = "gray-600",
  title,
  text,
  newTag,
  buy,
  detail,
  productList,
}) {
  const isMobile = window.innerWidth <= 767;

  return (
    <Link
      to={"/product-detail"}
      className="border hover:border-accent p-2 md:p-4 rounded-2xl bg-white border-gray-100 min-h-[200px] md:min-h-[320px] flex flex-col justify-between gap-4"
    >
      <div
        className={`w-12 h-12 md:w-16 md:h-16 rounded-2xl flex items-center justify-center`}
        style={{ backgroundColor: imgColor }}
      >
        <img src={cardIcon} alt={title} className="w-3/5" />
      </div>

      <div>
        <span className={`block text-${subTitleColor} text-p-xs md:text-p-sm`}>
          {subTitle}
        </span>

        <span className="inline-flex items-center text-primaryA text-p-md md:text-h4 font-medium mt-1">
          {title}
          {newTag && (
            <span className="inline-block py-0.5 px-1 md:px-2 rounded-full text-p-xs text-purple-600 bg-purple-100 ml-2">
              Yeni
            </span>
          )}
        </span>

        <p
          className={`text-gray-600 text-p-xs md:text-p-md mt-1 ${
            productList && "hidden md:block"
          }`}
        >
          {text}
        </p>

        {buy && (
          <Button size={isMobile ? "small" : "large"} block className="mt-4">
            Buy Now
          </Button>
        )}
          {detail && (
          <Button size={isMobile ? "small" : "large"} block className="mt-4">
            View Detail
          </Button>
        )}
      </div>
    </Link>
  );
}

export default CardItem;
const Alert = {
  lineWidth: 0,
  withDescriptionPadding: 16,
  colorText: "#0D0F11",
  colorTextHeading: "#0D0F11",
  colorInfoBg: "#DCF1FF",
  colorWarningBg: "#FFF3D3",
  colorErrorBg: "#FFDDE4",
  colorSuccessBg: "#D3F8DF",
};

export default Alert;

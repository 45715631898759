import React from "react";

import { Tabs, Select } from "antd";
import { NavArrowDown } from "iconoir-react";

import { base, state } from "./code";

import selectAnatomyImg from "assets/img/documentation/select/select-anatomy.png";
import selectSpecsImg from "assets/img/documentation/select/select-specs.png";

import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

export default function DocumentationSelect() {
  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <Select
                className="w-[32.5rem]"
                placeholder="Placeholder"
                size="large"
                suffixIcon={
                  <NavArrowDown
                    width={20}
                    height={20}
                    className="text-gray-800"
                  />
                }
                options={[
                  {
                    value: "option1",
                    label: "Option 1",
                  },
                  {
                    value: "option2",
                    label: "Option 2",
                  },
                  {
                    value: "option3",
                    label: "Option 3",
                  },
                  {
                    value: "option4",
                    label: "Option 4",
                  },
                ]}
              />
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={<img src={selectAnatomyImg} alt="Anatomy"></img>}
          />

          <DocumentationCard
            code={state}
            title="Examples (State)"
            content={
              <div className="flex flex-col gap-12 items-center justify-center py-12">
                <Select
                  open={true}
                  className="w-[32.5rem] mb-40"
                  defaultValue="option_1"
                  placeholder="Placeholder"
                  size="large"
                  suffixIcon={
                    <NavArrowDown
                      width={20}
                      height={20}
                      className="text-gray-800"
                    />
                  }
                >
                  <Select.Option value="option_1">
                    <div className="flex items-center gap-2">
                      <span className="p-md text-primaryA font-medium">
                        Lorem Ipsum1
                      </span>
                      <span className="p-md text-gray-600 font-medium">
                        @username
                      </span>
                    </div>
                  </Select.Option>
                  <Select.Option value="option_2">
                    <div className="flex items-center gap-2">
                      <span className="p-md text-primaryA font-medium">
                        Lorem Ipsum2
                      </span>
                      <span className="p-md text-gray-600 font-medium">
                        @username
                      </span>
                    </div>
                  </Select.Option>
                  <Select.Option value="option_3">
                    <div className="flex items-center gap-2">
                      <span className="p-md text-primaryA font-medium">
                        Lorem Ipsum3
                      </span>
                      <span className="p-md text-gray-600 font-medium">
                        @username
                      </span>
                    </div>
                  </Select.Option>
                </Select>

                <Select
                  className="w-[32.5rem]"
                  defaultValue="lucy"
                  size="large"
                  suffixIcon={
                    <NavArrowDown
                      width={20}
                      height={20}
                      className="text-gray-800"
                    />
                  }
                  options={[
                    {
                      value: "lucy",
                      label: "Lucy",
                    },
                  ]}
                />

                <Select
                  disabled
                  className="w-[32.5rem]"
                  placeholder="Placeholder"
                  size="large"
                  suffixIcon={
                    <NavArrowDown
                      width={20}
                      height={20}
                      className="text-gray-800"
                    />
                  }
                />

                <Select
                  placeholder="Placeholder"
                  status="error"
                  className="w-[32.5rem]"
                  size="large"
                  suffixIcon={
                    <NavArrowDown
                      width={20}
                      height={20}
                      className="text-gray-800"
                    />
                  }
                />

                <Select
                  placeholder="Placeholder"
                  status="warning"
                  className="w-[32.5rem]"
                  size="large"
                  suffixIcon={
                    <NavArrowDown
                      width={20}
                      height={20}
                      className="text-gray-800"
                    />
                  }
                />
              </div>
            }
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={selectSpecsImg}
            column={true}
            title="Default"
            list={[
              {
                text: "Direction",
                value: "Vertical",
              },
              {
                text: "Alignment",
                value: "Top Left",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Hug",
              },
              {
                text: "Item Spacing",
                value: 8,
              },
              {
                text: "Padding left",
                value: 16,
              },
              {
                text: "Padding right",
                value: 16,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

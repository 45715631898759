import React from "react";

import { Badge, Space, Tabs } from "antd";
import { HeartSolid } from "iconoir-react";

import anatomy from "assets/img/documentation/badge/badge-anatomy.svg";
import specs1 from "assets/img/documentation/badge/badge-specs-default.svg";
import specs2 from "assets/img/documentation/badge/badge-specs-label.svg";

import { base } from "./code";
import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

export default function DocumentationBadge() {
  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <Space size="middle">
                <Badge color="#007FDF" status="processing" />

                <Badge
                  count={
                    <div className="bg-blue-600 rounded-full w-4 h-4 !flex items-center justify-center">
                      <HeartSolid
                        className="text-white"
                        width={10}
                        height={10}
                      />
                    </div>
                  }
                />

                <Badge count={5} color="#007FDF" />
              </Space>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={
              <div className="bg-gray-50 rounded-lg">
                <img src={anatomy} alt="Modal" />
              </div>
            }
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={specs1}
            title="Default"
            column={true}
            list={[
              {
                text: "Direction",
                value: "Horizontal",
              },
              {
                text: "Alignment",
                value: "Middle left",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Hug",
              },
            ]}
          />

          <DocumentationSpecsCard
            img={specs2}
            title="Label Only Small"
            column={true}
            list={[
              {
                text: "Direction",
                value: "Horizontal",
              },
              {
                text: "Alignment",
                value: "Middle Center",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Hug",
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

import React from "react";

import { Skeleton, Tabs } from "antd";

import { base } from "./code";

import anatomy from "assets/img/documentation/skelaton/skelaton-anatomy.svg";

import DocumentationCard from "components/DocumentationCard";

const items = [
  {
    key: "1",
    label: "Overview",
    children: (
      <div className="mt-10">
        <DocumentationCard
          code={base}
          content={
            <Skeleton.Avatar shape={"square"} size={48} color="#A6A6A6" />
          }
        />

        <DocumentationCard
          title="Anatomy"
          content={<img src={anatomy} alt="Anatomy"></img>}
        />
      </div>
    ),
  },
];

export default function DocumentationSkelaton() {
  return <Tabs defaultActiveKey="1" items={items} />;
}

export const base = `import React, { useState } from "react";

import { Input } from "antd";

export default function Input() {
    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (e, setInputValue) => {
        setInputValue(e.target.value);
    };
    
    return (
        <Input
            size="large"
            placeholder="Placeholder"
            onChange={(e) => handleInputChange(e, setInputValue)}
            className={inputValue ? "active" : ""}
        />
    );
}`;

export const handle = `import React from "react";

import { Form, Input } from "antd";
import { CheckCircleSolid, WarningCircleSolid } from "iconoir-react";

export default function Input() {
    return (
        <div className="flex flex-col gap-12 w-[32.5rem]">
            <Input placeholder="Enable" size="large" />

            <Input
                className="active"
                defaultValue="Active"
                placeholder="Active"
                allowClear
                size="large"
            />

            <Form.Item className="mb-0" validateStatus="error">
                <Input
                placeholder="Error"
                defaultValue="Error"
                size="large"
                allowClear
                suffix={
                    <WarningCircleSolid
                        width={20}
                        height={20}
                        className="text-negative"
                    />
                }
                />
            </Form.Item>

            <Form.Item className="mb-0" validateStatus="success">
                <Input
                placeholder="Success"
                defaultValue="Success"
                size="large"
                allowClear
                suffix={
                    <CheckCircleSolid
                        width={20}
                        height={20}
                        className="text-positive"
                    />
                }
                />
            </Form.Item>

            <Form.Item
                className="mb-0"
                hasFeedback
                validateStatus="validating"
            >
                <Input
                    size="large"
                    placeholder="Loading"
                    defaultValue="Loading"
                />
            </Form.Item>

            <Input size="large" readOnly placeholder="Read Only" />

            <Input size="large" disabled placeholder="Disable" />
        </div>
    );
}`;

import { Breadcrumb, Button } from "antd";
import { NavArrowRight, StarSolid } from "iconoir-react";

import companyLogo from "assets/img/ZeroXBadge.svg";
import CardItem from "components/CardItem";

function VendorDetail() {
  return (
    <>
      <Breadcrumb
        separator={<NavArrowRight className="mt-0.5" width={18} height={18} />}
        items={[{ title: "Vendors" }, { title: "Hypeople" }]}
      />

      <div className="lg:flex items-center mb-12 md:mb-24 mt-8 md:mt-16 gap-28">
        <div className="flex-1 overflow-hidden">
          <div className="flex items-center gap-4">
            <img src={companyLogo} alt="Compound AI" />
            <div>
              <span className="block text-p-md text-gray-600">
                Member since November 2012
              </span>
              <span className="block text-h4 md:text-4xl font-medium text-primaryA">
                HYPEOPLE LTD
              </span>
            </div>
          </div>

          <p className="text-p-sm md:text-p-lg text-gray-600 mt-8">
            On the other hand, we denounce with righteous indignation and
            dislike men who are so beguiled and demoralized by the charms of
            pleasure of the moment, so blinded by desire, that they cannot
            foresee the pain and trouble that are boin.
          </p>

          <div className="mt-8 flex items-center gap-12">
            <div>
              <span className="block mb-2 text-p-md font-medium text-gray-400">
                Author Rating
              </span>
              <span className="flex gap-2 items-center text-3xl font-medium text-primaryA">
                4.5
                <div className="flex gap-1">
                  <StarSolid width={14} className="text-accent" />
                  <StarSolid width={14} className="text-accent" />
                  <StarSolid width={14} className="text-accent" />
                  <StarSolid width={14} className="text-gray-100" />
                  <StarSolid width={14} className="text-gray-100" />
                </div>
              </span>
            </div>

            <div>
              <span className="block mb-2 text-p-md font-medium text-gray-400">
                Sales
              </span>
              <span className="block text-3xl font-medium text-primaryA">
                684
              </span>
            </div>
          </div>
        </div>

        <div className="w-full max-w-[400px] p-4 rounded-xl border border-gray-100 mt-8 lg:mt-0">
          <span className="block text-p-lg md:text-h4 font-medium text-accent">
            Support Details
          </span>

          <div className="mt-6 flex flex-col gap-1">
            <div className="flex items-center justify-between">
              <span className="block text-gray-600 text-p-sm md:text-p-md">
                Supported By
              </span>
              <span className="block text-primaryA text-p-sm md:text-p-md">
                Hypeople LTD.
              </span>
            </div>

            <div className="flex items-center justify-between">
              <span className="block text-gray-600 text-p-sm md:text-p-md">
                Hours
              </span>
              <span className="block text-primaryA text-p-sm md:text-p-md">
                8-5pm
              </span>
            </div>

            <div className="flex items-center justify-between">
              <span className="block text-gray-600 text-p-sm md:text-p-md">
                Support URL
              </span>
              <span className="block text-primaryA text-p-sm md:text-p-md">
                https://www.hypeople.studio
              </span>
            </div>
          </div>

          <Button size="large" block className="mt-6">
            Button
          </Button>
        </div>
      </div>

      <div className="flex items-center justify-between mb-6">
        <span className="flex items-center gap-1 md:gap-4 text-accent text-h4 md:text-3xl font-medium">
          Products
          <span className="bg-blue-100 text-blue-600 text-p-xs md:text-p-sm rounded-full px-2 md:px-3 py-1">
            6 Solutions
          </span>
        </span>
      </div>

      <div className="grid grid-cols-2 xl:grid-cols-4 gap-4 md:gap-6">
        <CardItem
          imgColor="#A578D2"
          subTitle="APPLICATION"
          subTitleColor="#852AF3"
          title="Compound AI"
          text="Sürdürülebilir müşteri memnuniyeti ancak mümkündür."
        />

        <CardItem
          imgColor="#16465A"
          subTitle="APPLICATION"
          subTitleColor="#852AF3"
          title="Merchantfy"
          text="Sürdürülebilir müşteri memnuniyeti ancak mümkündür."
        />

        <CardItem
          imgColor="#E9906B"
          subTitle="APPLICATION"
          subTitleColor="#852AF3"
          title="Compound AI"
          text="Sürdürülebilir müşteri memnuniyeti ancak mümkündür."
        />

        <CardItem
          imgColor="#37BE7E"
          subTitle="Bankacılık ve Finans"
          subTitleColor="#852AF3"
          title="Flux Financial"
          text="Sürdürülebilir müşteri memnuniyeti ancak mümkündür."
          newTag={true}
        />

        <CardItem
          imgColor="#E9906B"
          subTitle="APPLICATION"
          subTitleColor="#852AF3"
          title="Compound AI"
          text="Sürdürülebilir müşteri memnuniyeti ancak mümkündür."
        />

        <CardItem
          imgColor="#A578D2"
          subTitle="APPLICATION"
          subTitleColor="#852AF3"
          title="Compound AI"
          text="Sürdürülebilir müşteri memnuniyeti ancak mümkündür."
        />
      </div>
    </>
  );
}

export default VendorDetail;

import { Link } from "react-router-dom";

import cardDots from "assets/img/card-dots.svg";

function DashboardCard({ url, img, date, title, desc, author }) {
  return (
    <Link
      to={url}
      className="block w-full border hover:border-accent border-gray-100 rounded-3xl overflow-hidden"
    >
      {img && (
        <div
          className="bg-gray-50 pt-4 md:pt-10 bg-cover bg-center bg-no-repeat overflow-hidden relative"
          style={{ backgroundImage: "url(" + cardDots + ")" }}
        >
          <img
            src={img}
            alt="Expense management software for better financial control!"
            className="mx-auto -mb-24 md:-mb-12 lg:mb-[-180px]"
          />
          <div className="absolute bottom-0 left-0 right-0 h-16 bg-gradient-to-t from-white to-transparent"></div>
        </div>
      )}

      <div className="p-4 md:pt-6 md:px-6 md:pb-14">
        {date && (
          <span className="block text-purple-500 text-p-sm">{date}</span>
        )}

        {title && (
          <span className="block text-primaryA text-p-lg md:text-h5 font-medium mt-2">
            {title}
          </span>
        )}

        {desc && (
          <p className="text-gray-600 text-p-xs md:text-p-md mt-2">{desc}</p>
        )}

        {author && (
          <div className="flex items-center gap-2 mt-4">
            <span className="flex bg-blue-950 w-6 h-6 md:w-9 md:h-9 rounded-full items-center justify-center text-p-xs md:text-p-md text-white font-medium">
              {author.split(" ")[0][0] +
                "" +
                (author.split(" ")[1] ? author.split(" ")[1][0] : "")}
            </span>
            <span className="text-blue-950 text-p-xs md:text-p-lg">
              {author}
            </span>
          </div>
        )}
      </div>
    </Link>
  );
}

export default DashboardCard;

export const base = `import React from "react";

import { Button } from "antd";

export default function Button() {
  return (
    <>
        <Button type="primary" size="large">Primary</Button>
        <Button size="large">Secondary</Button>
        <Button type="text" size="large">Tertiary</Button>
        <Button size="large" danger>Danger</Button>
        <Button type="success" size="large">Success</Button>
    </>
  );
}`;

export const anatomy = `import React from "react";

import { Button } from "antd";
import { Flash, NavArrowDown } from "iconoir-react";

export default function Button() {
  return (
    <Button
      type="primary"
      size="large"
      icon={<Flash />}
    >
      <span>Button</span>
      <NavArrowDown className="ml-1" />
    </Button>
  );
} `;


export const successBtn = `import React from "react";

import { Button } from "antd";

export default function Button() {
  return (
    <>
      <Button type="success" size="large">Enabled</Button>
      <Button type="success" size="large" disabled>Disabled</Button>
    </>
  );
} `;

export const dangerBtn = `import React from "react";

import { Button } from "antd";

export default function Button() {
  return (
    <>
      <Button danger size="large">Enabled</Button>
      <Button danger disabled size="large">Disabled</Button>
    </>
  );
} `;

export const tertiaryBtn = `import React from "react";

import { Button } from "antd";

export default function Button() {
  return (
    <>
      <Button type="text" size="large">Enabled</Button>
      <Button type="text" size="large" disabled>Disabled</Button>
    </>
  );
} `;

export const secondaryBtn = `import React from "react";

import { Button } from "antd";

export default function Button() {
  return (
    <>
      <Button size="large">Enabled</Button>
      <Button size="large" disabled>Disabled</Button>
    </>
  );
} `;

export const primaryBtn = `import React from "react";

import { Button } from "antd";

export default function Button() {
  return (
    <>
      <Button type="primary" size="large">Enabled</Button>
      <Button type="primary" size="large" disabled>Disabled</Button>
    </>
  );
} `;

export const shape = `import React from "react";

import { Button } from "antd";
import { Flash } from "iconoir-react";

export default function Button() {
  return (
    <>
      <Button type="primary" size="large">
        Halfround
      </Button>

      <Button type="primary" size="large" shape="round" >
        Fullround
      </Button>

      <Button
        type="primary"
        size="large"
        icon={<Flash />}
      ></Button>

      <Button
        type="primary"
        shape="circle"
        size="large"
        icon={<Flash />}
      ></Button>
    </>
  );
} `;
import React from "react";

import { Button, Tabs } from "antd";

import { Flash, NavArrowDown } from "iconoir-react";

import {
  base,
  successBtn,
  dangerBtn,
  tertiaryBtn,
  secondaryBtn,
  primaryBtn,
  shape,
  anatomy,
} from "./code";

import buttonSpecsImgSm from "assets/img/documentation/button/button-specs-img-sm.png";
import buttonSpecsImgMd from "assets/img/documentation/button/button-specs-img-md.png";
import buttonSpecsImgLg from "assets/img/documentation/button/button-specs-img-lg.png";
import buttonAnatomy from "assets/img/documentation/button/button-anatomy.svg";

import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

const items = [
  {
    key: "1",
    label: "Overview",
    children: (
      <div className="mt-10">
        <DocumentationCard
          code={base}
          content={
            <div className="flex items-center flex-wrap gap-4">
              <Button type="primary" size="large">
                Primary
              </Button>

              <Button size="large">Secondary</Button>

              <Button type="text" size="large">
                Tertiary
              </Button>

              <Button size="large" danger>
                Danger
              </Button>

              <Button type="success" size="large">
                Success
              </Button>
            </div>
          }
        />

        <DocumentationCard
          code={anatomy}
          title="Anatomy"
          content={<img src={buttonAnatomy} alt="Anatomy"></img>}
        />

        <DocumentationCard
          code={shape}
          title="Shape"
          desc="Button shapes include full round, half round, circular, and square."
          content={
            <div className="flex items-center flex-wrap gap-4">
              <div className="text-center">
                <Button
                  className="mb-4"
                  type="primary"
                  size="large"
                  icon={<Flash />}
                >
                  <span>Button</span>
                  <NavArrowDown className="ml-1" />
                </Button>
                <div className="text-p-md text-gray-600">Halfround</div>
              </div>

              <div className="text-center">
                <Button
                  className="mb-4"
                  type="primary"
                  size="large"
                  shape="round"
                >
                  Fullround
                </Button>
                <div className="text-p-md text-gray-600">Fullround</div>
              </div>

              <div className="text-center">
                <Button
                  className="mb-4"
                  type="primary"
                  size="large"
                  icon={<Flash />}
                ></Button>
                <div className="text-p-md text-gray-600">Rectangle</div>
              </div>

              <div className="text-center">
                <Button
                  className="mb-4"
                  type="primary"
                  shape="circle"
                  size="large"
                  icon={<Flash />}
                ></Button>
                <div className="text-p-md text-gray-600">Circle</div>
              </div>
            </div>
          }
        />

        <DocumentationCard
          code={primaryBtn}
          title="Colors"
          subTitle="Primary Button"
          content={
            <div className="flex items-center flex-wrap gap-4">
              <Button type="primary" size="large">
                Enabled
              </Button>

              <Button type="primary" disabled size="large">
                Disabled
              </Button>
            </div>
          }
        />

        <DocumentationCard
          code={secondaryBtn}
          subTitle="Secondary Button"
          content={
            <div className="flex items-center flex-wrap gap-4">
              <Button size="large">Enabled</Button>

              <Button disabled size="large">
                Disabled
              </Button>
            </div>
          }
        />

        <DocumentationCard
          code={tertiaryBtn}
          subTitle="Tertiary Button"
          content={
            <div className="flex items-center flex-wrap gap-4">
              <Button type="text" size="large">
                Enabled
              </Button>

              <Button type="text" disabled size="large">
                Disabled
              </Button>
            </div>
          }
        />

        <DocumentationCard
          code={dangerBtn}
          subTitle="Danger Button"
          content={
            <div className="flex items-center flex-wrap gap-4">
              <Button danger size="large">
                Enabled
              </Button>

              <Button danger disabled size="large">
                Disabled
              </Button>
            </div>
          }
        />

        <DocumentationCard
          code={successBtn}
          subTitle="Success Button"
          content={
            <div className="flex items-center flex-wrap gap-4">
              <Button type="success" size="large">
                Enabled
              </Button>

              <Button type="success" size="large" disabled>
                Disabled
              </Button>
            </div>
          }
        />
      </div>
    ),
  },
  {
    key: "2",
    label: "Specs",
    children: (
      <div className="mt-10">
        <DocumentationSpecsCard
          img={buttonSpecsImgSm}
          title="Small"
          list={[
            {
              text: "Item spacing",
              value: 8,
            },
            {
              text: "Padding",
              value: 14,
            },
            {
              text: "Corner Radius",
              value: 8,
            },
            {
              text: "Icon Size",
              value: 16,
            },
            {
              text: "Text Label medium(12/16)",
            },
          ]}
        />

        <DocumentationSpecsCard
          img={buttonSpecsImgMd}
          title="Medium"
          list={[
            {
              text: "Item spacing",
              value: 8,
            },
            {
              text: "Padding",
              value: 16,
            },
            {
              text: "Corner Radius",
              value: 8,
            },
            {
              text: "Icon Size",
              value: 16,
            },
            {
              text: "Text Label medium(16/24)",
            },
          ]}
        />

        <DocumentationSpecsCard
          img={buttonSpecsImgLg}
          title="Large"
          list={[
            {
              text: "Item spacing",
              value: 8,
            },
            {
              text: "Padding",
              value: 16,
            },
            {
              text: "Corner Radius",
              value: 8,
            },
            {
              text: "Icon Size",
              value: 20,
            },
            {
              text: "Text Label medium(16/24)",
            },
          ]}
        />
      </div>
    ),
  },
];

export default function DocumentationButton() {
  return <Tabs defaultActiveKey="1" items={items} />;
}

import React from "react";

import { Alert, Space, Tabs } from "antd";
import {
  CheckCircleSolid,
  InfoCircleSolid,
  WarningTriangleSolid,
  XmarkCircleSolid,
} from "iconoir-react";

import anatomy from "assets/img/documentation/alert/alert-anatomy.svg";
import specs from "assets/img/documentation/alert/alert-specs.svg";

import { base } from "./code";
import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

export default function DocumentationAlert() {
  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <Space
                direction="vertical"
                style={{
                  maxWidth: 375,
                  width: "100%",
                }}
              >
                <Alert
                  icon={<WarningTriangleSolid width={24} height={24} />}
                  message="Headline text"
                  description="Paragraph text"
                  type="warning"
                  showIcon
                />
                <Alert
                  icon={<InfoCircleSolid width={24} height={24} />}
                  message="Headline text"
                  description="Paragraph text"
                  type="info"
                  showIcon
                />
                <Alert
                  icon={<CheckCircleSolid width={24} height={24} />}
                  message="Headline text"
                  description="Paragraph text"
                  type="success"
                  showIcon
                />
                <Alert
                  icon={<XmarkCircleSolid width={24} height={24} />}
                  message="Headline text"
                  description="Paragraph text"
                  type="error"
                  showIcon
                />
              </Space>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={
              <div className="bg-gray-50 rounded-lg">
                <img src={anatomy} alt="Modal" />
              </div>
            }
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={specs}
            title="Default"
            column={true}
            list={[
              {
                text: "Direction",
                value: "Vertical",
              },
              {
                text: "Alignment",
                value: "Top left",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Fixed",
              },
              {
                text: "Padding",
                value: "16",
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

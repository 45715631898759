export const base = `import React from "react";

import { TimePicker } from "antd";
import { NavArrowDown } from "iconoir-react";

const format = "HH:mm";

export default function TimePicker() {
  return (
    <div className="flex flex-col gap-2 w-40">
        <div className="text-p-sm font-medium text-primaryA">Time</div>
        <TimePicker
            className="text-gray-600"
            format={format}
            placeholder="00:00 AM"
            suffixIcon={<NavArrowDown className="text-gray-600" />}
        />
        <span className="text-p-sm text-[#5E5E5E]">Hint</span>
    </div>
  );
}`;
import React, { useState } from "react";

import { Breadcrumb, Button, Modal, Space } from "antd";
import { NavArrowRight, WarningTriangleSolid } from "iconoir-react";

import companyLogo from "assets/img/product-detail-logo.svg";

function Checkout() {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div className="page-container">
      <div className="mb-12 mt-12">
        <Breadcrumb
          separator={
            <NavArrowRight className="mt-0.5" width={18} height={18} />
          }
          items={[
            { title: "Kategoriler" },
            { title: "Bankacılık ve Finans" },
            { title: "Zero X" },
            { title: "Satın Alma" },
          ]}
        />
      </div>

      <Modal
        title={
          <div className="relative z-10">
            <div className="w-16 h-16 rounded-full bg-white border border-gray-400 flex items-center justify-center mb-7">
              <WarningTriangleSolid
                width={36}
                height={36}
                className="text-warning"
              />
            </div>

            <span className="block text-h4 text-primaryA font-normal">
              Satın Alma Onayı
            </span>
          </div>
        }
        closeIcon={false}
        okText={"Evet, Satın Al"}
        cancelText={"Hayır"}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        className="custom-modal warning-modal"
      >
        <p className="text-p-md text-gray-600 mb-8">
          Seçtiğiniz ürünü satın almak üzeresiniz. Bu işlemi onaylamak istiyor
          musunuz?
        </p>
      </Modal>

      <div className="mb-16">
        <h1 className="text-gray-800 text-h5 mb-8">Satın Alma Özeti</h1>

        <div className="flex items-center gap-4 p-4 mb-4 border rounded-2xl">
          <img src={companyLogo} alt="Compound AI" />
          <div>
            <span className="block text-p-md text-gray-600">Hypeople</span>
            <span className="block text-h6 font-medium text-primaryA">
              Compound AI
            </span>
          </div>
        </div>

        <div className="flex flex-col gap-2 mb-3">
          <div className="flex items-center justify-between">
            <span className="text-gray-600 text-p-sm md:text-label-lg font-medium">
              Hizmet Paket Adı
            </span>
            <span className="text-primaryA text-p-sm md:text-label-lg font-medium">
              Compound AI
            </span>
          </div>

          <div className="flex items-center justify-between">
            <span className="text-gray-600 text-p-sm md:text-label-lg font-medium">
              Hizmet Kullanım Ölçü Birimi
            </span>
            <span className="text-primaryA text-p-sm md:text-label-lg font-medium">
              Commercial
            </span>
          </div>

          <div className="flex items-center justify-between">
            <span className="text-gray-600 text-p-sm md:text-label-lg font-medium">
              Hizmet Fiyat Tipi
            </span>
            <span className="text-primaryA text-p-sm md:text-label-lg font-medium">
              1200£
            </span>
          </div>

          <div className="flex items-center justify-between">
            <span className="text-gray-600 text-p-sm md:text-label-lg font-medium">
              Birim Fiyat / Abonelik Fiyatı
            </span>
            <span className="text-primaryA text-p-sm md:text-label-lg font-medium">
              120£
            </span>
          </div>

          <div className="flex items-center justify-between">
            <span className="text-gray-600 text-p-sm md:text-label-lg font-medium">
              Hizmet Fiyatı Döviz Cinsi
            </span>
            <span className="text-primaryA text-p-sm md:text-label-lg font-medium">
              £
            </span>
          </div>
        </div>

        <div className="flex items-center justify-between border-t border-dashed pt-2">
          <span className="text-gray-600 text-p-sm md:text-label-lg font-medium">
            Total
          </span>
          <span className="text-primaryA text-h5 font-medium">1200£</span>
        </div>

        <Space wrap className="block">
          <Button
            onClick={showModal}
            htmlType="submit"
            type="primary"
            block
            className="mt-8"
          >
            Satın Al
          </Button>
        </Space>
      </div>
    </div>
  );
}
export default Checkout;

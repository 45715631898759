import React from "react";

import { Tabs, Form, Input, InputNumber } from "antd";
import { CheckCircleSolid, WarningCircleSolid } from "iconoir-react";

import { base, handle } from "./code";

import inputAnatomyImg from "assets/img/documentation/input-number/input-number-anatomy.svg";
import inputSpecsImg from "assets/img/documentation/input-number/input-number-specs.svg";

import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

export default function DocumentationInputNumber() {
  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            column={true}
            content={
              <div className="w-[32.5rem]">
                <InputNumber
                  size="large"
                  placeholder="Placeholder"
                  className="w-full"
                />
              </div>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={<img src={inputAnatomyImg} alt="Anatomy"></img>}
          />

          <DocumentationCard
            column={true}
            code={handle}
            title="Handle"
            content={
              <div className="flex flex-col gap-12 w-[32.5rem]">
                <InputNumber
                  placeholder="Enable"
                  size="large"
                  className="w-full"
                />

                <Form.Item className="mb-0" validateStatus="error">
                  <InputNumber
                    placeholder="Error"
                    defaultValue="Error"
                    size="large"
                    className="w-full"
                  />
                </Form.Item>

                <Form.Item className="mb-0" validateStatus="success">
                  <InputNumber
                    placeholder="Success"
                    defaultValue="Success"
                    size="large"
                    className="w-full"
                  />
                </Form.Item>

                <InputNumber
                  size="large"
                  disabled
                  placeholder="Disable"
                  className="w-full"
                />
              </div>
            }
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={inputSpecsImg}
            column={true}
            title="Default"
            list={[
              {
                text: "Direction",
                value: "Horizontal",
              },
              {
                text: "Alignment",
                value: "Middle Left",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Fixed",
              },
              {
                text: "Padding left",
                value: 16,
              },
              {
                text: "Padding right",
                value: 16,
              },
              {
                text: "Padding top",
                value: 8,
              },
              {
                text: "Padding bottom",
                value: 8,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

import React from "react";

import { Breadcrumb, Tabs } from "antd";
import { Folder, NavArrowRight, Page } from "iconoir-react";

import { base } from "./code";

import breadcrumbAnatomy from "assets/img/documentation/breadcrumb/breadcrumb-anatomy.png";
import breadcrumbSpecsDefault from "assets/img/documentation/breadcrumb/breadcrumb-specs-default.png";

import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

const items = [
  {
    key: "1",
    label: "Overview",
    children: (
      <div className="mt-10">
        <DocumentationCard
          code={base}
          content={
            <div>
              <Breadcrumb
                separator={
                  <NavArrowRight className="mt-0.5" width={18} height={18} />
                }
                items={[
                  {
                    title: "Link",
                  },
                  {
                    title: "Link",
                  },
                  {
                    title: "Link",
                  },
                  {
                    title: "Link",
                  },
                  {
                    title: "Link",
                    href: "",
                  },
                  {
                    title: "Link",
                    href: "",
                  },
                  {
                    title: "Link",
                    href: "",
                  },
                  {
                    title: "Current page",
                  },
                ]}
              />

              <Breadcrumb
                className="mt-12"
                separator={
                  <NavArrowRight className="mt-0.5" width={18} height={18} />
                }
                items={[
                  {
                    href: "",
                    title: (
                      <div className="flex items-center gap-1">
                        <Folder width={16} height={16} />
                        <span>Link</span>
                      </div>
                    ),
                  },
                  {
                    href: "",
                    title: (
                      <div className="flex items-center gap-1">
                        <Folder width={16} height={16} />
                        <span>Link</span>
                      </div>
                    ),
                  },
                  {
                    href: "",
                    title: (
                      <div className="flex items-center gap-1">
                        <Folder width={16} height={16} />
                        <span>Link</span>
                      </div>
                    ),
                  },
                  {
                    href: "",
                    title: (
                      <div className="flex items-center gap-1">
                        <Folder width={16} height={16} />
                        <span>Link</span>
                      </div>
                    ),
                  },
                  {
                    href: "",
                    title: (
                      <div className="flex items-center gap-1">
                        <Folder width={16} height={16} />
                        <span>Link</span>
                      </div>
                    ),
                  },
                  {
                    href: "",
                    title: (
                      <div className="flex items-center gap-1">
                        <Folder width={16} height={16} />
                        <span>Link</span>
                      </div>
                    ),
                  },
                  {
                    title: (
                      <div className="flex items-center gap-1">
                        <Page width={16} height={16} />
                        <span>Current Page</span>
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          }
        />
        <DocumentationCard
          title="Anatomy"
          content={
            <div className="w-full min-h-[260px] max-h-[260px] h-full flex items-center justify-center bg-gray-50 gap-6 rounded-lg">
              <img src={breadcrumbAnatomy} alt="" />
            </div>
          }
        />
      </div>
    ),
  },
  {
    key: "2",
    label: "Specs",
    children: (
      <div className="mt-10">
        <DocumentationSpecsCard
          img={breadcrumbSpecsDefault}
          title="Default"
          column={true}
          list={[
            {
              text: "Item spacing",
              value: 8,
            },
            {
              text: "Padding",
              value: 16,
            },
            {
              text: "Text Label medium(12/16)",
            },
          ]}
        />
      </div>
    ),
  },
];

export default function DocumentationBreadcrumb() {
  return <Tabs defaultActiveKey="1" items={items} />;
}

import React from "react";

import { Button, Calendar, Select, Tabs } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import dayLocaleData from "dayjs/plugin/localeData";

import anatomy from "assets/img/documentation/calendar/calendar-anatomy.svg";
import specs from "assets/img/documentation/calendar/calendar-specs.svg";

import { base } from "./code";
import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";
import { NavArrowDown, NavArrowLeft, NavArrowRight } from "iconoir-react";

export default function DocumentationCalendar() {
  dayjs.extend(dayLocaleData);

  const onPanelChange = (value, mode) => {
    console.log(value.format("YYYY-MM-DD"), mode);
  };

  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <div className="w-[350px] border border-gray-100 rounded-2xl overflow-hidden">
                <Calendar
                  fullscreen={false}
                  headerRender={({ value, type, onChange, onTypeChange }) => {
                    const start = 0;
                    const end = 12;
                    const monthOptions = [];
                    let current = value.clone();
                    const localeData = value.localeData();
                    const months = [];
                    for (let i = 0; i < 12; i++) {
                      current = current.month(i);
                      months.push(localeData.monthsShort(current));
                    }
                    for (let i = start; i < end; i++) {
                      monthOptions.push(
                        <Select.Option key={i} value={i} className="month-item">
                          {months[i]}
                        </Select.Option>
                      );
                    }
                    const year = value.year();
                    const month = value.month();
                    const options = [];
                    for (let i = year - 10; i < year + 10; i += 1) {
                      options.push(
                        <Select.Option key={i} value={i} className="year-item">
                          {i}
                        </Select.Option>
                      );
                    }
                    return (
                      <div className="flex items-center justify-between p-4">
                        <Button
                          className="w-[40px] h-[40px] p-0"
                          onClick={() => {
                            const now = value.clone().month(month - 1);
                            onChange(now);
                          }}
                        >
                          <NavArrowLeft width={24} height={24} />
                        </Button>

                        <div className="flex items-center">
                          <Select
                            suffixIcon={<NavArrowDown width={24} height={24} />}
                            value={month}
                            onChange={(newMonth) => {
                              const now = value.clone().month(newMonth);
                              onChange(now);
                            }}
                            className="select-noborder"
                          >
                            {monthOptions}
                          </Select>

                          <Select
                            suffixIcon={<NavArrowDown width={24} height={24} />}
                            value={year}
                            onChange={(newYear) => {
                              const now = value.clone().year(newYear);
                              onChange(now);
                            }}
                            className="select-noborder"
                          >
                            {options}
                          </Select>
                        </div>

                        <Button
                          className="w-[40px] h-[40px] p-0"
                          onClick={() => {
                            const now = value.clone().month(month + 1);
                            onChange(now);
                          }}
                        >
                          <NavArrowRight width={24} height={24} />
                        </Button>
                      </div>
                    );
                  }}
                  onPanelChange={onPanelChange}
                />
              </div>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={
              <div className="bg-gray-50 rounded-lg">
                <img src={anatomy} alt="Modal" />
              </div>
            }
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={specs}
            title="Divider"
            column={true}
            list={[
              {
                text: "Direction",
                value: "Vertical",
              },
              {
                text: "Alignment",
                value: "Middle Center",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Fixed",
              },
              {
                text: "Padding Left",
                value: "16",
              },
              {
                text: "Padding Right",
                value: "16",
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

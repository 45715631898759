export const base = `import React from "react";

import { Button, Result } from "antd";
import {
  CheckCircleSolid,
  InfoCircleSolid,
  WarningTriangleSolid,
  XmarkCircleSolid,
} from "iconoir-react";

export default function Result() {
  return (
    <div>
      <Result
        icon={false}
        title={
          <span className="block font-semibold text-p-lg text-primaryA">
            Single line headline
          </span>
        }
        subTitle={
          <p className="text-p-md text-gray-600 max-w-[300px]">
            Proin ut dui sed metus pharetra hend rerit vel non mi.
            Nulla ornare faucibus.
          </p>
        }
        extra={[
          <Button key={1}>Label</Button>,
          <Button key={2} type="primary">Label</Button>,
        ]}
      />

      <Result
        icon={
          <div className="flex justify-center">
            <XmarkCircleSolid
              width={64}
              height={64}
              className="text-negative"
            />
          </div>
        }
        title={
          <span className="block font-semibold text-p-lg text-primaryA">
            Single line headline
          </span>
        }
        subTitle={
          <p className="text-p-md text-gray-600 max-w-[300px]">
            Proin ut dui sed metus pharetra hend rerit vel non mi.
            Nulla ornare faucibus.
          </p>
        }
        extra={[
          <Button key={1}>Label</Button>,
          <Button key={2} danger>Label</Button>,
        ]}
      />

      <Result
        icon={
          <div className="flex justify-center">
            <WarningTriangleSolid
              width={64}
              height={64}
              className="text-warning"
            />
          </div>
        }
        title={
          <span className="block font-semibold text-p-lg text-primaryA">
            Single line headline
          </span>
        }
        subTitle={
          <p className="text-p-md text-gray-600 max-w-[300px]">
            Proin ut dui sed metus pharetra hend rerit vel non mi.
            Nulla ornare faucibus.
          </p>
        }
        extra={[
          <Button key={1}>Label</Button>,
          <Button key={2} type="primary">Label</Button>,
        ]}
      />

      <Result
        icon={
          <div className="flex justify-center">
            <CheckCircleSolid
              width={64}
              height={64}
              className="text-positive"
            />
          </div>
        }
        title={
          <span className="block font-semibold text-p-lg text-primaryA">
            Single line headline
          </span>
        }
        subTitle={
          <p className="text-p-md text-gray-600 max-w-[300px]">
            Proin ut dui sed metus pharetra hend rerit vel non mi.
            Nulla ornare faucibus.
          </p>
        }
        extra={[
          <Button key={1}>Label</Button>,
          <Button key={2} type="success">Label</Button>,
        ]}
      />

      <Result
        icon={
          <div className="flex justify-center">
            <InfoCircleSolid
              width={64}
              height={64}
              className="text-blue-600"
            />
          </div>
        }
        title={
          <span className="block font-semibold text-p-lg text-primaryA">
            Single line headline
          </span>
        }
        subTitle={
          <p className="text-p-md text-gray-600 max-w-[300px]">
            Proin ut dui sed metus pharetra hend rerit vel non mi.
            Nulla ornare faucibus.
          </p>
        }
        extra={[
          <Button key={1}>Label</Button>,
          <Button key={2} type="primary">Label</Button>,
        ]}
      />
    </div>
  );
}`;
import React from "react";

import { Tabs } from "antd";

import DocumentationTextCard from "components/DocumentationTextCard";

const items = [
  {
    key: "1",
    label: "Overview",
    children: (
      <div className="mt-12">
        <DocumentationTextCard
          list={[
            {
              text: "Icon Library",
              value: "Iconoir",
            },
            {
              text: "Website",
              link: true,
              URL: "https://iconoir.com",
            },
            {
              text: "License",
              link: true,
              URL: "https://github.com/iconoir-icons/iconoir/blob/main/LICENSE",
            },
          ]}
        ></DocumentationTextCard>
      </div>
    ),
  },
];

export default function DocumentationIcons() {
  return <Tabs defaultActiveKey="1" items={items} />;
}

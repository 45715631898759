import React, { useRef, useState } from "react";

import {
  Breadcrumb,
  Button,
  Radio,
  Row,
  Col,
  Space,
  Tabs,
  Modal,
  Carousel,
} from "antd";

import { NavArrowLeft, NavArrowRight, Xmark } from "iconoir-react";

import CardItemSection from "components/CardItemSection";

import companyLogo from "assets/img/product-detail-logo.svg";
import slideImage from "assets/img/product-detail-slide-image.png";
import documentationImage from "assets/img/product-detail-documentation-image.png";
import documentationPlayIcon from "assets/img/product-detail-documentation-play.svg";

function ProductDetail() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [value, setValue] = useState(1);

  // Carousel
  const carouselRef = useRef(null);

  const documentationData = [
    {
      img: documentationImage,
    },
    {
      img: documentationImage,
    },
    {
      img: documentationImage,
      link: "https://www.youtube.com/watch?v=K4TOrB7at0Y",
    },
    {
      img: documentationImage,
    },
  ];

  const showModal = (index) => {
    setIsModalVisible(true);
    setTimeout(() => {
      carouselRef.current.goTo(index);
    }, 300);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const next = () => {
    carouselRef.current.next();
  };

  const prev = () => {
    carouselRef.current.prev();
  };

  // Modal
  const CustomCloseIcon = (
    <div
      className="p-1 lg:p-2 border border-gray-100 rounded-full bg-white hover:bg-white mr-7 shadow-sm"
      onClick={handleClose}
    >
      <Xmark
        height={16}
        width={16}
        className="rounded-full p-0 m-0 text-black bg-white drop-shadow-none md:w-6 md:h-6"
      />
    </div>
  );

  // Radio
  const onChange = (e) => {
    setValue(e.target.value);
  };

  // Tabs
  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-3 md:mt-8">
          <div className="mb-4 pb-4">
            <span className="block text-p-sm md:text-h4 text-accent mb-4">
              Description
            </span>
            <p className="text-p-sm md:text-p-lg text-gray-600 mt-1">
              On the other hand, we denounce with righteous indignation and
              dislike men who are so beguiled and demoralized by the charms of
              pleasure of the moment, so blinded by desire, that they cannot
              foresee the pain and trouble that are bound to ensue; and equal
              blame belongs to those who fail in their duty through weakness of
              will, which is the same as saying through shrinking from toil and
              pain.
            </p>
          </div>

          <div className="mb-4 pb-4">
            <p className="text-p-sm md:text-p-lg text-gray-600 mt-1">
              These cases are perfectly simple and easy to distinguish. In a
              free hour, when our power of choice is untrammelled and when
              nothing prevents our being able to do what we like best, every
              pleasure is to be welcomed and every pain avoided. But in certain
              circumstances and owing to the claims of duty or the obligations
              of business it will frequently occur that pleasures have to be
              repudiated and annoyances accepted. The wise man therefore always
              holds in these matters to this principle of selection: he rejects
              pleasures to secure other greater pleasures, or else he endures
              pains to avoid worse pains.
            </p>
          </div>

          <div className="mb-4 pb-4">
            <p className="text-p-sm md:text-p-lg text-gray-600 mt-1">
              These cases are perfectly simple and easy to distinguish. In a
              free hour, when our power of choice is untrammelled and when
              nothing prevents our being able to do what we like best, every
              pleasure is to be welcomed and every pain avoided. But in certain
              circumstances and owing to the claims of duty or the obligations
              of business it will frequently occur that pleasures have to be
              repudiated and annoyances accepted. The wise man therefore always
              holds in these matters to this principle of selection: he rejects
              pleasures to secure other greater pleasures, or else he endures
              pains to avoid worse pains.
            </p>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Plans",
      children: (
        <div className="mt-3 md:mt-8">
          <div className="mb-4 pb-4 border-b border-gray-100">
            <span className="block text-p-sm md:text-p-lg text-primaryA font-medium">
              Simple Gateway
            </span>
            <p className="text-p-sm md:text-p-lg text-gray-600 mt-1">
              These cases are perfectly simple and easy to distinguish. In a
              free hour, when our power of choice is untrammelled and when
              nothing prevents our being able to do what we like best, every
              pleasure is to be welcomed and every pain avoided. But in certain
              circumstances and owing to the claims of duty or the obligations
              of business it will frequently occur that pleasures have to be
              repudiated and annoyances accepted.
            </p>
          </div>

          <div className="mb-4 pb-4 border-b border-gray-100">
            <span className="block text-p-sm md:text-p-lg text-primaryA font-medium">
              Scale Set
            </span>
            <p className="text-p-sm md:text-p-lg text-gray-600 mt-1">
              These cases are perfectly simple and easy to distinguish. In a
              free hour, when our power of choice is untrammelled and when
              nothing prevents our being able to do what we like best, every
              pleasure is to be welcomed and every pain avoided. But in certain
              circumstances and owing to the claims of duty or the obligations
              of business it will frequently occur that pleasures have to be
              repudiated and annoyances accepted.
            </p>
          </div>

          <div className="mb-4 pb-4 border-b border-gray-100">
            <span className="block text-p-sm md:text-p-lg text-primaryA font-medium">
              Simple Gateway
            </span>
            <p className="text-p-sm md:text-p-lg text-gray-600 mt-1">
              These cases are perfectly simple and easy to distinguish. In a
              free hour, when our power of choice is untrammelled and when
              nothing prevents our being able to do what we like best, every
              pleasure is to be welcomed and every pain avoided. But in certain
              circumstances and owing to the claims of duty or the obligations
              of business it will frequently occur that pleasures have to be
              repudiated and annoyances accepted.
            </p>
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: "Documentation",
      children: (
        <div className="mt-3 md:mt-8">
          <div className="mb-4 pb-4">
            <Row gutter={[16, 24]}>
              {documentationData.map((item, index) => (
                <Col
                  key={index}
                  span={6}
                  xs={12}
                  sm={8}
                  md={6}
                  lg={8}
                  xl={8}
                  xxl={6}
                  onClick={() => showModal(index)}
                  className="cursor-pointer"
                >
                  <img
                    src={item.img}
                    className="w-full h-full max-h-[540px] object-cover"
                    alt="Documentation"
                  />
                  {item.link && (
                    <img
                      src={documentationPlayIcon}
                      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                      alt="Documentation Play Icon"
                    />
                  )}
                </Col>
              ))}
            </Row>

            <Modal
              closeIcon={CustomCloseIcon}
              footer={null}
              keyboard={true}
              open={isModalVisible}
              onCancel={handleClose}
              width={900}
            >
              <Carousel
                ref={carouselRef}
                dots={true}
                infinite={false}
                className="relative mt-9 md:mt-14"
              >
                {documentationData.map((item, index) => (
                  <div key={index} className="relative h-[55vw] lg:h-[540px]">
                    {item.link ? (
                      <iframe
                        src="https://www.youtube.com/embed/K4TOrB7at0Y?si=dqUzPkbOm1ZgyQ9g"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                        className="w-full h-full"
                      ></iframe>
                    ) : (
                      <img
                        src={item.img}
                        className="w-full h-full object-cover"
                        alt="Documentation"
                      />
                    )}
                  </div>
                ))}
              </Carousel>

              <NavArrowLeft
                onClick={prev}
                width={32}
                height={32}
                className="absolute cursor-pointer z-50 left-8 top-1/2 -translate-y-1/2 bg-white rounded-full p-2 md:w-10 md:h-10"
              />
              <NavArrowRight
                onClick={next}
                width={32}
                height={32}
                className="absolute cursor-pointer z-50 right-8 top-1/2 -translate-y-1/2 bg-white rounded-full p-2 md:w-10 md:h-10"
              />
            </Modal>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Breadcrumb
        separator={<NavArrowRight className="mt-0.5" width={18} height={18} />}
        items={[
          { title: "Kategoriler" },
          { title: "Bankacılık ve Finans" },
          { title: "Zero X" },
        ]}
      />

      <div className="lg:flex mb-12 mt-8 md:mt-10 gap-32">
        <div className="flex-1 overflow-hidden">
          <img
            src={slideImage}
            alt="Compound AI"
            className="rounded-2xl w-full"
          />

          <Tabs defaultActiveKey="1" items={items} className="mt-12" />
        </div>

        <div className="w-full  max-w-[400px]">
          <div className="items-center gap-4 p-4 rounded-xl border border-gray-100 mt-8 lg:mt-0 mb-6">
            <img src={companyLogo} alt="Compound AI" />
            <div className="my-8">
              <span className="block text-p-md text-gray-600">Hypeople</span>
              <span className="block text-h4 md:text-h4 font-medium text-primaryA">
                Compound AI
              </span>
            </div>
            <Radio.Group
              onChange={onChange}
              value={value}
              className="w-full radio-group"
            >
              <Space direction="vertical" className="w-full">
                <Radio value={1} className="w-full">
                  <span>Commerical</span>
                  <span>1200£</span>
                </Radio>
                <Radio value={2} className="w-full">
                  <span>Extended</span>
                  <span>3400£</span>
                </Radio>
              </Space>
            </Radio.Group>

            <Button type="primary" size="large" block className="mt-4">
              Buy Now
            </Button>
          </div>
          <div className="items-center gap-4 p-4 rounded-xl border border-gray-100 mt-8 lg:mt-0">
            <span className="text-primaryA text-h5 mb-6 inline-block">
              Additional Links
            </span>
            <div className="flex flex-col gap-3 mb-6">
              <div className="flex justify-between">
                <span className="text-gray-500 text-p-md">Category</span>
                <span className="text-primaryA text-p-md">
                  Bankacılık ve Finans
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-500 text-p-md">Type</span>
                <span className="text-primaryA text-p-md">Lorem ipsum</span>
              </div>
            </div>
            <Button size="large" block className="mt-4">
              Zero X User Guide
            </Button>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-16 md:gap-24 mt-14 md:mt-48">
        <CardItemSection title="More Hypeople" />
      </div>
    </>
  );
}

export default ProductDetail;

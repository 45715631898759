import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Header from "layouts/Header";
import Footer from "layouts/Footer";

const DefaultLayout = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <>
      <Header />

      <main className="mx-4 lg:mx-12 mb-16 md:mb-24 mt-8">{children}</main>

      <Footer />
    </>
  );
};

export default DefaultLayout;

export const base = `import React, { useState } from 'react';

import { Pagination, InputNumber, Select, Button } from "antd";
import { NavArrowDown, NavArrowLeft, NavArrowRight } from "iconoir-react";

export default function Pagination() {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const itemRender = (_, type, originalElement) => {
        if (type === "prev") {
        return (
            <Button size="small" className="mr-1" icon={<NavArrowLeft />}></Button>
        );
        }
        if (type === "next") {
        return (
            <Button size="small" className="ml-1" icon={<NavArrowRight />}></Button>
        );
        }
        return originalElement;
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
    };

    const handleJumpChange = (value) => {
        setCurrentPage(value);
    };

    return (
        <>
            <Pagination
                current={currentPage}
                total={1000}
                pageSize={pageSize}
                showSizeChanger={false}
                showQuickJumper={false}
                onChange={handlePageChange}
                onShowSizeChange={handlePageSizeChange}
                itemRender={itemRender}
            />
            <div className="flex items-center mt-4 gap-4">
                <div>
                    <span className="text-p-sm mr-1">Go to page:</span>
                    <InputNumber
                        min={1}
                        max={1000}
                        size="large"
                        value={currentPage}
                        onChange={handleJumpChange}
                    />
                </div>

                <div>
                <span className="text-p-sm mr-1">items per page:</span>
                    <Select
                        value={pageSize}
                        style={{ width: 100 }}
                        onChange={handlePageSizeChange}
                        suffixIcon={
                        <NavArrowDown
                            width={20}
                            height={20}
                            className="text-gray-800"
                        />
                        }
                    >
                        <Select.Option value={10}>10</Select.Option>
                        <Select.Option value={20}>20</Select.Option>
                        <Select.Option value={50}>50</Select.Option>
                    </Select>
                </div>
            </div>

            <div className="mt-4 text-p-xs">
                <span>Items: </span>
                <span>
                    {(currentPage - 1) * pageSize + 1} -{" "}
                    {currentPage * pageSize}
                </span>
                <span> of 1000 items</span>
            </div>
        </>
    )
} `;
export const base = `import React from "react";

import { Tabs } from "antd";
import { HeartSolid } from "iconoir-react";

export default function Tabs() {
  return (
    <div className="py-10 w-full max-w-[435px]">
      <Tabs
        className="bg-white p-2 default-tab"
        defaultActiveKey="1"
        items={[0, 1].map((_, i) => {
          const id = String(i + 1);
          return {
            label: (
              <div className="flex items-center gap-2">
                <HeartSolid width={18} height={18} />
                Tab {id}
              </div>
            ),
            key: id,
            children: (
              <p className="text-primaryA px-4 pb-4">Tab {id}</p>
            ),
          };
        })}
      />

      <Tabs
        className="bg-white mt-10 p-2"
        onChange={onChange}
        type="card"
        items={new Array(3).fill(null).map((_, i) => {
          const id = String(i + 1);
          return {
            label: "Tab " + id,
            key: id,
            children: (
              <p className="text-primaryA px-4 pb-4">Tab {id}</p>
            ),
          };
        })}
      />
    </div>
  );
}`;
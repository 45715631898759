export const base = `import React from "react";

import { Slider } from "antd";
import { Heart, HeartSolid } from "iconoir-react";

const IconSlider = (props) => {
    const { max, min } = props;
    const [value, setValue] = useState(0);
    const preColorCls = "ant-heart";
    const nextColorCls = "ant-heart-solid";
  
    return (
      <div className="flex items-center justify-center gap-4">
        <Heart className={preColorCls}/>
        <Slider defaultValue={50} {...props} onChange={setValue}  />
        <HeartSolid className={nextColorCls} />
      </div>
    );
};

export default function Slider() {
  return (
    <div className="w-full my-auto h-full flex flex-col items-center gap-10">
        <IconSlider min={0} max={100} className="w-[19rem]" />

        <Slider
            className="w-96"
            range={{
            draggableTrack: true,
            }}
            defaultValue={[20, 70]}
        />

        <Slider min={0} max={100} defaultValue={50} className="w-96" />
    </div>
  );
}`;

export const handle = `import React from "react";

import { Slider } from "antd";

export default function Slider() {
  return (
    <div className="flex gap-8">
        <Slider value={100} />
        <Slider disabled />
    </div>
  );
}`;
import React from "react";

import { Tabs, Menu, Checkbox } from "antd";

import { base } from "./code";

import menuAnatomyImg from "assets/img/documentation/menu/menu-anatomy.png";
import menuSpecsImg from "assets/img/documentation/menu/menu-specs.png";

import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

const handleClick = (e) => {
  console.log("Menu item clicked:", e);
};

export default function DocumentationMenu() {
  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <Menu
                onClick={handleClick}
                mode="vertical"
                style={{ width: "50%" }}
                className="rounded-lg"
              >
                <Menu.SubMenu title="Menu" expandIcon={false}>
                  <Menu.Item key="1">
                    <div className="flex items-center gap-2">
                      <Checkbox checked />
                      <span className="p-md text-primaryA font-medium">
                        Lorem Ipsum
                      </span>
                      <span className="p-md text-gray-600 font-medium">
                        @username
                      </span>
                    </div>
                  </Menu.Item>

                  <Menu.Item key="2">
                    <div className="flex items-center gap-2">
                      <Checkbox />
                      <span className="p-md text-primaryA font-medium">
                        Lorem Ipsum
                      </span>
                      <span className="p-md text-gray-600 font-medium">
                        @username
                      </span>
                    </div>
                  </Menu.Item>

                  <Menu.Item key="3">
                    <div className="flex items-center gap-2">
                      <Checkbox />
                      <span className="p-md text-primaryA font-medium">
                        Lorem Ipsum
                      </span>
                      <span className="p-md text-gray-600 font-medium">
                        @username
                      </span>
                    </div>
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={<img src={menuAnatomyImg} alt="Anatomy"></img>}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            column={true}
            img={menuSpecsImg}
            title="Menu"
            list={[
              {
                text: "Direction",
                value: "Horizontal",
              },
              {
                text: "Alignment",
                value: "Middle Center",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Fixed",
              },
              {
                text: "Item Spacing",
                value: "8",
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

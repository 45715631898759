export const base = `import React from "react";

import { Button, Modal } from "antd";

export default function Modal() {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button type="primary" onClick={() => setModalOpen(true)}>
        Open Modal
      </Button>

      <Modal
        centered
        closable={false}
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        className="modal-content-padding"
      >
        <div className="h-[240px] bg-blue-600"></div>

        <div className="p-6">
          <h5 className="text-h4 font-normal text-primaryA mb-2">
            Title
          </h5>

          <p className="text-p-md font-normal text-gray-600">
            Proin ut dui sed metus pharetra hend rerit vel non mi.
            Nulla ornare faucibus ex, non facilisis nisl. Maecenas
            aliquet mauris ut tempus Proin ut dui sed metus pharetra
            hend rerit vel non mi.
          </p>
        </div>
      </Modal>
    </>
  );
}`;
export const base = `import React from 'react';

import { Menu, Checkbox } from "antd";

const handleClick = (e) => {
  console.log("Menu item clicked:", e);
};

export default function Menu() {
  return (
    <Menu
      onClick={handleClick}
      mode="vertical"
      style={{ width: "50%" }}
      className="rounded-lg"
    >
      <Menu.SubMenu title="Menu" expandIcon={false}>
        <Menu.Item key="1">
          <div className="flex items-center gap-2">
            <Checkbox checked />
            <span className="p-md text-primaryA font-medium">
              Lorem Ipsum
            </span>
            <span className="p-md text-gray-600 font-medium">
              @username
            </span>
          </div>
        </Menu.Item>

        <Menu.Item key="2">
          <div className="flex items-center gap-2">
            <Checkbox />
            <span className="p-md text-primaryA font-medium">
              Lorem Ipsum
            </span>
            <span className="p-md text-gray-600 font-medium">
              @username
            </span>
          </div>
        </Menu.Item>

        <Menu.Item key="3">
          <div className="flex items-center gap-2">
            <Checkbox />
            <span className="p-md text-primaryA font-medium">
              Lorem Ipsum
            </span>
            <span className="p-md text-gray-600 font-medium">
              @username
            </span>
          </div>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  )
} `;
import React, { useState } from "react";

import { Tabs, Steps } from "antd";

import { base } from "./code";

import stepsAnatomyImg from "assets/img/documentation/steps/steps-anatomy.svg";
import stepsSpecsImg from "assets/img/documentation/steps/steps-specs.png";

import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

const steps = [
  {
    title: "Home",
  },
  {
    title: "Label",
  },
  {
    title: "Label",
  },
];

export default function DocumentationSteps() {
  const [current] = useState(0);

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const items1 = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <div className="w-full max-w-[360px]">
                <Steps current={current} items={items} />
              </div>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={<img src={stepsAnatomyImg} alt="Anatomy"></img>}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            column={true}
            img={stepsSpecsImg}
            title="Horizontal Steps"
            list={[
              {
                text: "Direction",
                value: "Horizontal",
              },
              {
                text: "Alignment",
                value: "Top Left",
              },
              {
                text: "Vertical resizing",
                value: "Fixed",
              },
              {
                text: "Padding top-bottom",
                value: 16,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items1} />;
}

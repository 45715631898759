export const base = `import React from "react";

import { Avatar } from "antd";
import { User } from "iconoir-react";

export default function Avatar() {
  return (
    <div className="flex flex-wrap items-center justify-center gap-12">
      <Avatar size={40} className="bg-accent font-bold">
        AB
      </Avatar>
      <Avatar size={40} icon={<User />} className="bg-black" />
      <Avatar size={40} src={img} />
    </div>
  );
}`;
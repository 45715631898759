export const base = `import React from "react";

import { Breadcrumb } from "antd";
import { Folder, NavArrowRight, Page } from "iconoir-react";

export default function Breadcrumb() {
  return (
    <>
      <Breadcrumb
        separator={
          <NavArrowRight className="mt-0.5" width={18} height={18} />
        }
        items={[
          {
            title: "Link",
          },
          {
            title: "Link",
          },
          {
            title: "Link",
          },
          {
            title: "Link",
          },
          {
            title: "Link",
            href: "",
          },
          {
            title: "Link",
            href: "",
          },
          {
            title: "Link",
            href: "",
          },
          {
            title: "Current page",
          },
        ]}
      />

      <Breadcrumb
        className="mt-12"
        separator={
          <NavArrowRight className="mt-0.5" width={18} height={18} />
        }
        items={[
          {
            href: "",
            title: (
              <div className="flex items-center gap-1">
                <Folder width={16} height={16} />
                <span>Link</span>
              </div>
            ),
          },
          {
            href: "",
            title: (
              <div className="flex items-center gap-1">
                <Folder width={16} height={16} />
                <span>Link</span>
              </div>
            ),
          },
          {
            href: "",
            title: (
              <div className="flex items-center gap-1">
                <Folder width={16} height={16} />
                <span>Link</span>
              </div>
            ),
          },
          {
            href: "",
            title: (
              <div className="flex items-center gap-1">
                <Folder width={16} height={16} />
                <span>Link</span>
              </div>
            ),
          },
          {
            href: "",
            title: (
              <div className="flex items-center gap-1">
                <Folder width={16} height={16} />
                <span>Link</span>
              </div>
            ),
          },
          {
            href: "",
            title: (
              <div className="flex items-center gap-1">
                <Folder width={16} height={16} />
                <span>Link</span>
              </div>
            ),
          },
          {
            title: (
              <div className="flex items-center gap-1">
                <Page width={16} height={16} />
                <span>Current Page</span>
              </div>
            ),
          },
        ]}
      />
    </>
  );
} `;
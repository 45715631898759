import React from "react";

import { Space, Tabs, Tag } from "antd";
import { Heart } from "iconoir-react";

import anatomy from "assets/img/documentation/tag/tag-anatomy.svg";
import specs from "assets/img/documentation/tag/tag-specs.svg";

import { base, colors } from "./code";
import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

export default function DocumentationTag() {
  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <Space size={[10]} wrap>
                <Tag>Label</Tag>
                <Tag color="red">Label</Tag>
                <Tag color="orange">Label</Tag>
                <Tag color="gold">Label</Tag>
                <Tag color="green">Label</Tag>
                <Tag color="blue">Label</Tag>
                <Tag color="purple">Label</Tag>
                <Tag color="magenta">Label</Tag>
              </Space>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={
              <div className="bg-gray-50 rounded-lg">
                <img src={anatomy} alt="Modal" />
              </div>
            }
          />

          <DocumentationCard
            code={colors}
            title="Colors"
            content={
              <div className="w-full py-20 px-12 flex justify-center gap-12">
                <div>
                  <div className="flex flex-col items-start gap-12">
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                    >
                      Primary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      color="red"
                    >
                      Primary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      color="orange"
                    >
                      Primary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      color="gold"
                    >
                      Primary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      color="green"
                    >
                      Primary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      color="blue"
                    >
                      Primary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      color="purple"
                    >
                      Primary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      color="magenta"
                    >
                      Primary
                    </Tag>
                  </div>
                </div>

                <div>
                  <div className="flex flex-col items-start gap-12">
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      className="bg-gray-500 text-white"
                    >
                      Secondary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      className="bg-negative text-white"
                    >
                      Secondary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      className="bg-orange-600 text-white"
                    >
                      Secondary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      className="bg-yellow-500 text-white"
                    >
                      Secondary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      className="bg-green-600 text-white"
                    >
                      Secondary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      className="bg-blue-600 text-white"
                    >
                      Secondary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      className="bg-purple-600 text-white"
                    >
                      Secondary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      className="bg-magenta-600 text-white"
                    >
                      Secondary
                    </Tag>
                  </div>
                </div>

                <div>
                  <div className="flex flex-col items-start gap-12">
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      className="bg-transparent text-gray-500"
                    >
                      Tertiary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      className="bg-transparent text-negative"
                    >
                      Tertiary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      className="bg-transparent text-orange-600"
                    >
                      Tertiary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      className="bg-transparent text-yellow-500"
                    >
                      Tertiary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      className="bg-transparent text-green-600"
                    >
                      Tertiary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      className="bg-transparent text-blue-600"
                    >
                      Tertiary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      className="bg-transparent text-purple-600"
                    >
                      Tertiary
                    </Tag>
                    <Tag
                      icon={<Heart width={16} height={16} strokeWidth={2} />}
                      className="bg-transparent text-magenta-600"
                    >
                      Tertiary
                    </Tag>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={specs}
            title="Large"
            column={true}
            list={[
              {
                text: "Direction",
                value: "Vertical",
              },
              {
                text: "Alignment",
                value: "Middle Center",
              },
              {
                text: "Item spacing",
                value: "4",
              },
              {
                text: "Padding left",
                value: "12",
              },
              {
                text: "Padding right",
                value: "12",
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

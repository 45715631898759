import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const FullLayout = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="full-layout">
      {children}
    </div>
  );
}

export default FullLayout;

import React from "react";

import { Collapse, Tabs } from "antd";
import { Heart, NavArrowDown } from "iconoir-react";

import anatomy from "assets/img/documentation/collapse/collapse-anatomy.svg";
import specs from "assets/img/documentation/collapse/collapse-specs.svg";

import { base } from "./code";
import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

export default function DocumentationCollapse() {
  const collapseItems = [
    {
      key: "1",
      showArrow: false,
      label: (
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-6">
            <Heart width={24} height={24} />

            <div className="flex flex-col gap-1">
              <span className="block text-p-md text-primaryA font-medium">
                Label
              </span>
              <p className="text-p-sm font-medium text-gray-800">Paragraph</p>
              <span className="text-p-sm font-medium block text-gray-600">
                Support paragraph
              </span>
            </div>
          </div>

          <NavArrowDown width={24} height={24} />
        </div>
      ),
      children: (
        <p className="text-p-xs text-gray-800 mb-2">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
          error, recusandae, quo impedit assumenda vero rem illo modi aperiam
          cum eaque? Ex, laboriosam et harum fugit explicabo delectus nam odio.
        </p>
      ),
    },
  ];

  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <div className="flex flex-col gap-4 w-full max-w-[375px]">
                <Collapse
                  ghost
                  items={collapseItems}
                  defaultActiveKey={["1"]}
                  onChange={onChange}
                  className="default-collapse default-collapse-noradius"
                />

                <Collapse
                  ghost
                  items={collapseItems}
                  defaultActiveKey={["1"]}
                  onChange={onChange}
                  className="default-collapse"
                />
              </div>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={
              <div className="bg-gray-50 rounded-lg">
                <img src={anatomy} alt="Modal" />
              </div>
            }
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={specs}
            title="Divider"
            column={true}
            list={[
              {
                text: "Direction",
                value: "Vertical",
              },
              {
                text: "Alignment",
                value: "Middle Center",
              },
              {
                text: "Vertical resizing",
                value: "Fixed",
              },
              {
                text: "Horizontal resizing",
                value: "Fixed",
              },
              {
                text: "Item Spacing",
                value: "4",
              },
              {
                text: "Padding Left",
                value: "16",
              },
              {
                text: "Padding Right",
                value: "16",
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

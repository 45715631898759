export const base = `import React from "react";

import { Form, Input } from "antd";

export default function Form() {
  return (
    <Form className="w-80" layout="vertical" autoComplete="off">
        <Form.Item name="name" label="Label" extra="Hint">
            <Input placeholder="Placeholder" size="large" />
        </Form.Item>
    </Form>
  );
}`;

export const select = `import React from "react";

import { Form, Select } from "antd";
import { NavArrowDown } from "iconoir-react";

export default function Form() {
  return (
    <Form className="w-80" layout="vertical" autoComplete="off">
        <Form.Item name="name" label="Label" extra="Hint">
            <Select
                placeholder="Placeholder"
                allowClear
                suffixIcon={
                    <NavArrowDown
                    width={20}
                    height={20}
                    className="text-gray-800"
                    />
                }
                size="large"
            >
                <Select.Option value="option1">Option 1</Select.Option>
                <Select.Option value="option2">Option 2</Select.Option>
                <Select.Option value="option3">Option 3</Select.Option>
            </Select>
        </Form.Item>
    </Form>
  );
}`;

export const group = `import React from "react";

import { Form, Select, Input } from "antd";
import { NavArrowDown } from "iconoir-react";

export default function Form() {
  return (
    <Form
        className="w-[26.3rem] flex items-center"
        layout="vertical"
        autoComplete="off"
    >
        <Form.Item
            className="me-2"
            name="name"
            label="Label"
            extra="Hint"
        >
            <Select
                placeholder="Placeholder"
                defaultValue={"mr"}
                allowClear
                suffixIcon={
                    <NavArrowDown
                        width={20}
                        height={20}
                        className="text-gray-800"
                    />
                }
                size="large"
            >
                <Select.Option value="mr">Mr.</Select.Option>
                <Select.Option value="mrs">Mrs.</Select.Option>
            </Select>
        </Form.Item>

        <Form.Item className="w-full mt-2">
            <Input size="large" placeholder="John Doe" className="mb-2" />
        </Form.Item>
    </Form>
  );
}`;

export const country = `import React from "react";

import { Form } from "antd";

import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

export default function Form() {
  return (
    <Form className="w-96" layout="vertical" autoComplete="off">
        <Form.Item name="phone" label="Label" className="w-full">
            <IntlTelInput preferredCountries={["gb"]} />
        </Form.Item>
    </Form>
  );
}`;

export const stepper = `import React from "react";

import { Form, InputNumber } from "antd";

export default function Form() {
  return (
    <Form className="w-96" layout="vertical" autoComplete="off">
        <Form.Item label="Label" extra="Hint">
            <InputNumber
                keyboard={true}
                placeholder="Placeholder"
                size="large"
                className="w-full"
            />
        </Form.Item>
    </Form>
  );
}`;

export const password = `import React from "react";

import { Form, Input } from "antd";
import { Eye, EyeClosed } from "iconoir-react";

export default function Form() {
  return (
    <Form className="w-96" layout="vertical" autoComplete="off">
        <Form.Item name="password" label="Label" extra="Hint">
            <Input.Password
                placeholder="Placeholder"
                size="large"
                iconRender={(visible) =>
                    visible ? (
                        <span>
                            <Eye width={24} height={24} />
                        </span>
                    ) : (
                        <span>
                            <EyeClosed width={24} height={24} />
                        </span>
                    )
                }
            />
        </Form.Item>
    </Form>
  );
}`;

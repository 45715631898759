import { useState } from "react";
import { Link } from "react-router-dom";

import { Breadcrumb } from "antd";
import { NavArrowDown, NavArrowRight, Xmark } from "iconoir-react";

import CardItem from "components/CardItem";

function ProductList() {
  const isMobile = window.innerWidth <= 991;
  const [filterShow, setFilterShow] = useState(false);

  return (
    <>
      <Breadcrumb
        separator={<NavArrowRight className="mt-0.5" width={18} height={18} />}
        items={[{ title: "Kategoriler" }, { title: "Tüm Çözümler" }]}
      />

      <div className="flex items-center justify-between mt-12 lg:mt-0">
        <h1 className="text-accent text-h4 md:text-3xl font-medium flex items-center gap-1 md:gap-4 lg:mt-8">
          All Solutions
          <span className="bg-blue-100 text-blue-600 text-p-xs md:text-p-sm rounded-full px-2 md:px-3 py-1">
            323 Solutions
          </span>
        </h1>

        <div className="flex lg:hidden items-center md:gap-1 cursor-pointer">
          <span
            className="text-p-md font-medium text-primaryA"
            onClick={() => isMobile && setFilterShow(true)}
          >
            Filter
          </span>
          <NavArrowDown />
        </div>
      </div>

      <div className="lg:flex items-start mt-6 lg:mt-12 gap-6">
        <div className="lg:max-w-[320px] w-full mb-6 lg:mb-0">
          <ul
            style={{
              display: isMobile ? (filterShow ? "flex" : "none") : "flex",
            }}
            className="fixed top-0 left-0 right-0 bottom-0 bg-white z-10 py-12 px-5 overflow-auto lg:static lg:p-0 flex flex-col gap-3 text-gray-800 font-medium text-p-lg"
          >
            <div
              className="block lg:hidden fixed top-2 right-2"
              onClick={() => setFilterShow(false)}
            >
              <Xmark />
            </div>

            <li>
              <Link to="#" className="text-blue-600">
                All Solutions (16)
              </Link>
            </li>
            <li>
              <Link to="#">Bankacılık ve Finans (3)</Link>
            </li>
            <li>
              <Link to="#">Analytics (0)</Link>
            </li>
            <li>
              <Link to="#">Business Apps (2)</Link>
            </li>
            <li>
              <Link to="#">Chat (0)</Link>
            </li>
            <li>
              <Link to="#">Databases (1)</Link>
            </li>
            <li>
              <Link to="#">Data Science (3)</Link>
            </li>
            <li>
              <Link to="#">Developer Tools (1)</Link>
            </li>
            <li>
              <Link to="#">eLearning (4)</Link>
            </li>
            <li>
              <Link to="#">Frameworks (2)</Link>
            </li>
            <li>
              <Link to="#">Monitoring (0)</Link>
            </li>
            <li>
              <Link to="#">Network Tools (0)</Link>
            </li>
          </ul>
        </div>

        <div className="flex-1 overflow-hidden grid grid-cols-2 xl:grid-cols-3 gap-4 md:gap-6">
          {[1, 2, 3, 4, 5, 6, 7].map(() => (
            <CardItem
              imgColor="#16465A"
              subTitle="Hypeople"
              title="Merchantfy"
              text="Sürdürülebilir müşteri memnuniyeti ancak mümkündür."
              buy={true}
              productList={true}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default ProductList;

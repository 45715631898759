export const base = `import React from "react";

import { Select } from "antd";
import { NavArrowDown } from "iconoir-react";

export default function Select() {
  return (
    <Select
        className="w-[32.5rem]"
        placeholder="Placeholder"
        size="large"
        suffixIcon={
            <NavArrowDown
                width={20}
                height={20}
                className="text-gray-800"
            />
        }
        options={[
            {
                value: "option1",
                label: "Option 1",
            },
            {
                value: "option2",
                label: "Option 2",
            },
            {
                value: "option3",
                label: "Option 3",
            },
            {
                value: "option4",
                label: "Option 4",
            },
        ]}
    />
  );
}`;

export const state = `import React from "react";

import { Select } from "antd";
import { NavArrowDown } from "iconoir-react";

export default function Select() {
  return (
    <div className="flex flex-col gap-12 items-center justify-center py-12">
        <Select
            open={true}
            className="w-[32.5rem] mb-28"
            defaultValue="option_1"
            placeholder="Placeholder"
            size="large"
            suffixIcon={
                <NavArrowDown
                    width={20}
                    height={20}
                    className="text-gray-800"
                />
            }
        >
            <Select.Option value="option_1">
                <div className="flex items-center gap-2">
                    <span className="p-md text-primaryA font-medium">
                        Lorem Ipsum1
                    </span>
                    <span className="p-md text-gray-600 font-medium">
                        @username
                    </span>
                </div>
            </Select.Option>
            <Select.Option value="option_2">
                <div className="flex items-center gap-2">
                    <span className="p-md text-primaryA font-medium">
                        Lorem Ipsum2
                    </span>
                    <span className="p-md text-gray-600 font-medium">
                        @username
                    </span>
                </div>
            </Select.Option>
            <Select.Option value="option_3">
                <div className="flex items-center gap-2">
                    <span className="p-md text-primaryA font-medium">
                        Lorem Ipsum3
                    </span>
                    <span className="p-md text-gray-600 font-medium">
                        @username
                    </span>
                </div>
            </Select.Option>
        </Select>

        <Select
            className="w-[32.5rem]"
            defaultValue="lucy"
            size="large"
            suffixIcon={
                <NavArrowDown
                    width={20}
                    height={20}
                    className="text-gray-800"
                />
                }
            options={[
                {
                    value: "lucy",
                    label: "Lucy",
                },
            ]}
        />

        <Select
            disabled
            className="w-[32.5rem]"
            placeholder="Placeholder"
            size="large"
            suffixIcon={
                <NavArrowDown
                    width={20}
                    height={20}
                    className="text-gray-800"
                />
            }
        />

        <Select
            placeholder="Placeholder"
            status="error"
            className="w-[32.5rem]"
            size="large"
            suffixIcon={
                <NavArrowDown
                    width={20}
                    height={20}
                    className="text-gray-800"
                />
            }
        />

        <Select
            placeholder="Placeholder"
            status="warning"
            className="w-[32.5rem]"
            size="large"
            suffixIcon={
                <NavArrowDown
                    width={20}
                    height={20}
                    className="text-gray-800"
                />
            }
        />
    </div>
  );
}`;
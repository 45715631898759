import React from "react";

import { Breadcrumb, Button, Select } from "antd";
import { NavArrowDown, NavArrowRight } from "iconoir-react";

import companyLogo from "assets/img/product-detail-logo.svg";

export default function PaymentHistory() {
  let data = [
    {
      payment_invoice: "Eylül 2023",
      date: "Eylül 21, 2023",
      status: "Beklemede",
      price: "$218,80",
    },
    {
      payment_invoice: "Ağustos 2023",
      date: "Ağustos 21, 2023",
      status: "Ödendi",
      price: "$218,80",
    },
    {
      payment_invoice: "Haziran 2023",
      date: "Haziran 21, 2023",
      status: "Ödendi",
      price: "$218,80",
    },
  ];

  return (
    <div className="page-container">
      <div className="mb-12 mt-12">
        <Breadcrumb
          separator={
            <NavArrowRight className="mt-0.5" width={18} height={18} />
          }
          items={[
            { title: "My Subscriptions" },
            { title: "Compound AI" },
            { title: "Fatura Listesi" },
          ]}
        />
      </div>

      <div className="flex items-center gap-4 mb-8">
        <h1 className="text-primaryA text-h4 md:text-h1 font-medium flex items-center gap-1 md:gap-4">
          Fatura Listesi
        </h1>
        <span className="bg-blue-100 text-blue-600 text-p-xs md:text-p-sm rounded-full px-2 md:px-3 py-1">
          6 adet
        </span>
      </div>

      <div className="md:flex items-center justify-between border border-gray-100 rounded-2xl p-4 mb-11">
        <div className="flex items-center gap-4">
          <img src={companyLogo} alt="Compound AI" />
          <div>
            <span className="block text-p-md text-gray-600">Hypeople</span>
            <span className="block text-h6 font-medium text-primaryA">
              Compound AI
            </span>
          </div>
        </div>

        <div className="mt-3">
          <Select
            defaultValue="Fatura Dönemi"
            className="w-full md:w-[170px]"
            suffixIcon={<NavArrowDown width={24} height={24} />}
            options={[
              {
                value: "lastMonth",
                label: "Son Ay",
              },
              {
                value: "lastThreeMonth",
                label: "Son 3 Ay",
              },
              {
                value: "lastSixMonth",
                label: "Son 6 Ay",
              },
              {
                value: "lastYear",
                label: "Son 12 Ay",
              },
            ]}
          />
        </div>
      </div>

      {data &&
        data.map((item, index) => (
          <div
            key={index}
            className={`flex flex-col md:flex-row items-center justify-between border-gray-100 ${
              index > 0 && "border-t pt-5 mt-5"
            }`}
          >
            <div className="flex flex-col md:flex-row items-center gap-1 md:gap-3 w-full md:w-auto">
              <div className="flex justify-between items-center md:block md:items-start w-full md:w-[130px]">
                <div className="text-p-sm text-gray-400">Fatura Dönemi</div>
                <div className="text-p-md font-medium text-gray-800">
                  {item.payment_invoice}
                </div>
              </div>

              <div className="flex justify-between items-center md:block md:items-start w-full md:w-[155px]">
                <div className="text-p-sm text-gray-400">Fatura Tarihi</div>
                <div className="text-p-md font-medium text-gray-800">
                  {item.date}
                </div>
              </div>

              <div className="flex justify-between items-center md:block md:items-start w-full md:w-auto">
                <div className="text-p-sm text-gray-400 md:inline-block ">
                  Ödeme Durumu
                </div>
                <span className="flex items-center gap-2 text-p-md font-medium text-gray-800">
                  <div
                    className="w-[8px] h-[8px] rounded-full"
                    style={{
                      backgroundColor:
                        item.status === "Ödendi" ? "#0B8E51" : "#EE7202",
                    }}
                  ></div>
                  {item.status}
                </span>
              </div>
            </div>

            <div className="flex items-center justify-between gap-1 w-full md:w-auto mt-2 md:mt-0">
              <div className="order-1 md:order-none">
                <div className="text-h6 font-medium text-gray-800 leading-6">
                  {item.price}
                </div>
              </div>

              <Button
                type="text"
                size="large"
                className="text-p-md text-primaryA -ml-4 md:ml-0"
              >
                Fatura Detayı Aç
              </Button>
            </div>
          </div>
        ))}
    </div>
  );
}

import Tabs from "./Tabs";
import Radio from "./Radio";
import Input from "./Input";
import Button from "./Button";
import Breadcrumb from "./Breadcrumb";
import Message from "./Message";
import Alert from "./Alert";
import Badge from "./Badge";
import Tag from "./Tag";
import Tooltip from "./Tooltip";
import Progress from "./Progress";
import Steps from "./Steps";
import Switch from "./Switch";
import DatePicker from "./DatePicker";

const allComponentsOverrides = {
  Tabs,
  Radio,
  Input,
  Button,
  Breadcrumb,
  Message,
  Alert,
  Badge,
  Tag,
  Tooltip,
  Progress,
  Steps,
  Switch,
  DatePicker,
};

export default allComponentsOverrides;

import React, { useState } from "react";

import { Tabs, Upload, message, Progress, Button } from "antd";
import {
  CheckCircleSolid,
  CloudUpload,
  Xmark,
  XmarkCircleSolid,
} from "iconoir-react";

import { base } from "./code";

import uploadAnatomyImg2 from "assets/img/documentation/upload/upload-anatomy-2.svg";
import uploadAnatomyImg from "assets/img/documentation/upload/upload-anatomy.svg";
import uploadSpecsImg from "assets/img/documentation/upload/upload-specs.svg";

import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

const { Dragger } = Upload;

const allowedFileTypes = ["image/png", "image/jpeg"];

export default function DocumentationUpload() {
  const [messageApi, contextHolder] = message.useMessage();
  const [fileInfoList, setFileInfoList] = useState([]);

  function Message(text, type) {
    messageApi.open({
      icon:
        type === "error" ? (
          <XmarkCircleSolid width={24} height={24} className="text-white" />
        ) : (
          <CheckCircleSolid width={24} height={24} className="text-white" />
        ),
      content: text,
      style: {
        textAlign: "right",
      },
    });
  }

  const customRequest = ({ file, onSuccess, onError, onProgress }) => {
    if (!allowedFileTypes.includes(file.type)) {
      Message("Sadece PNG ve JPG dosyaları desteklenmektedir.", "error");
      onError(new Error("Sadece PNG ve JPG dosyaları desteklenmektedir."));
      return;
    }

    const maxSize = 5 * 1024 * 1024;
    if (file.size > maxSize) {
      Message("Dosya boyutu 5 MB'dan büyük olamaz.", "error");
      onError(new Error("Dosya boyutu 5 MB'dan büyük olamaz."));
      return;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      setFileInfoList((prevList) => [
        ...prevList,
        { id: file.uid, name: file.name, size: file.size, progress: 100 },
      ]);
      onProgress({ percent: 100 });
      onSuccess();

      console.log(file);
      Message(`${file.name} başarıyla yüklendi.`);
    };

    reader.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        setFileInfoList((prevList) =>
          prevList.map((info) =>
            info.id === file.uid
              ? { ...info, progress: Math.min(progress, 99) }
              : info
          )
        );
        onProgress({ percent: progress });
      }
    };

    reader.onerror = () => {
      Message(`Dosya yüklenirken bir hata oluştu.`, "error");
      onError(new Error("Dosya yüklenirken bir hata oluştu."));
    };

    reader.readAsDataURL(file);
  };

  const handleDelete = (fileId) => {
    setFileInfoList((prevList) =>
      prevList.filter((info) => info.id !== fileId)
    );
  };

  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <div className="w-full max-w-[450px]">
                {contextHolder}

                <Dragger
                  className="block h-[265px]"
                  customRequest={customRequest}
                  showUploadList={false}
                >
                  <div className="ant-upload-drag-icon flex justify-center">
                    <CloudUpload
                      width={48}
                      height={48}
                      className="text-gray-400"
                    />
                  </div>
                  <span className="block ant-upload-text mt-4">
                    Drag and drop or
                  </span>
                  <Button className="mt-2">Choose File</Button>
                </Dragger>

                {fileInfoList.length > 0 &&
                  fileInfoList.map((item, index) => (
                    <div
                      key={index}
                      className="mt-4 bg-white border border-gray-100 py-2 px-4 rounded-lg"
                    >
                      <div className="flex items-center justify-between">
                        <span className="flex-1 whitespace-nowrap text-ellipsis overflow-hidden block text-p-sm font-medium text-primaryA">
                          {item.name}
                        </span>

                        <div
                          className="cursor-pointer"
                          onClick={() => handleDelete(item.id)}
                        >
                          <Xmark className="hover:text-red-600" />
                        </div>
                      </div>
                      <span className="block text-p-xs font-medium text-gray-600">
                        {(item.size / 1024).toFixed(2)} KB
                      </span>
                      <Progress
                        strokeWidth={4}
                        percent={item.progress}
                        status="active"
                      />
                    </div>
                  ))}
              </div>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={<img src={uploadAnatomyImg2} alt="Anatomy"></img>}
          />

          <DocumentationCard
            title="Anatomy"
            content={<img src={uploadAnatomyImg} alt="Anatomy"></img>}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            full={true}
            img={uploadSpecsImg}
            column={true}
            title="Completed"
            list={[
              {
                text: "Direction",
                value: "Vertical",
              },
              {
                text: "Alignment",
                value: "Middle center",
              },
              {
                text: "Vertical resizing",
                value: "Fixed",
              },
              {
                text: "Horizontal resizing",
                value: "Fixed",
              },
              {
                text: "Item Spacing",
                value: 16,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

import React from "react";
import { Link } from "react-router-dom";

import footerLogo from "assets/img/kkb-footer-logo.svg";
import footerBG from "assets/img/footer-bg.svg";
import footerMobileBG from "assets/img/footer-bg-mobile.svg";

export default function Footer() {
  return (
    <footer className="relative m-4 lg:m-12 p-5 md:p-12 rounded-2xl md:rounded-3xl min-h-[516px] overflow-hidden">
      <div
        className="hidden md:block absolute top-0 left-0 w-full h-full bg-cover bg-center bg-no-repeat z-[-1]"
        style={{ backgroundImage: "url(" + footerBG + ")" }}
      ></div>
      <div
        className="block md:hidden absolute top-0 left-0 w-full h-full bg-cover bg-center bg-no-repeat z-[-1]"
        style={{ backgroundImage: "url(" + footerMobileBG + ")" }}
      ></div>

      <div className="lg:flex items-start justify-between gap-12">
        <div>
          <div className="flex items-center gap-3">
            <img src={footerLogo} alt="Logo" className="w-10 md:w-auto" />
          </div>
          <p className="hidden md:block text-gray-200 text-p-md mt-4">
            © 2023 Kredi Kayıt Bürosu. Tüm hakları saklıdır.
          </p>
        </div>

        <div className="max-w-[850px] w-full mt-6 md:mt-10 lg:mt-0">
          <span className="text-white text-h4 font-medium mb-6 block">
            Kategoriler
          </span>
          <div className="grid grid-cols-2 md:flex items-end justify-between gap-1 md:gap-5 text-gray-200 font-medium text-p-xs md:text-p-lg">
            <ul className="flex flex-col gap-4">
              <li>
                <Link to="#">Commercial Products</Link>
              </li>
              <li>
                <Link to="#">Bankacılık ve Finans</Link>
              </li>
              <li>
                <Link to="#">Analytics</Link>
              </li>
              <li>
                <Link to="#">Business Apps</Link>
              </li>
              <li>
                <Link to="#">Monitoring</Link>
              </li>
              <li>
                <Link to="#">Frameworks</Link>
              </li>
            </ul>

            <ul className="flex flex-col gap-4">
              <li>
                <Link to="#">Monitoring </Link>
              </li>
              <li>
                <Link to="#">Network Tools</Link>
              </li>
              <li>
                <Link to="#">App for Retail</Link>
              </li>
              <li>
                <Link to="#">App for Analytics AI</Link>
              </li>
              <li>
                <Link to="#">Commercial Products</Link>
              </li>
              <li>
                <Link to="#">Bankacılık ve Finans</Link>
              </li>
            </ul>

            <ul className="flex flex-col gap-4">
              <li>
                <Link to="#">Monitoring</Link>
              </li>
              <li>
                <Link to="#">Commercial Products</Link>
              </li>
              <li>
                <Link to="#">Bankacılık ve Finans</Link>
              </li>
              <li>
                <Link to="#">Network Tools</Link>
              </li>
              <li>
                <Link to="#">App for Retail</Link>
              </li>
              <li>
                <Link to="#">App for Analytics AI</Link>
              </li>
            </ul>
          </div>
        </div>

        <p className="block md:hidden text-gray-200 text-p-xs mt-6">
          © 2023 Kredi Kayıt Bürosu. Tüm hakları saklıdır.
        </p>
      </div>
    </footer>
  );
}

import React from "react";

import { Tabs, Form, Input, Select, InputNumber } from "antd";
import { Eye, EyeClosed, NavArrowDown } from "iconoir-react";

import { base, country, group, password, select, stepper } from "./code";

import formAnatomyImg from "assets/img/documentation/form/form-anatomy.png";
import formSpecsImg from "assets/img/documentation/form/form-specs.png";

import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

export default function DocumentationForm() {
  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <Form className="w-80" layout="vertical" autoComplete="off">
                <Form.Item name="name" label="Label" extra="Hint">
                  <Input placeholder="Placeholder" size="large" />
                </Form.Item>
              </Form>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={<img src={formAnatomyImg} alt="Anatomy"></img>}
          />

          <DocumentationCard
            code={select}
            title="Type"
            subTitle="Select"
            content={
              <Form className="w-80" layout="vertical" autoComplete="off">
                <Form.Item name="name" label="Label" extra="Hint">
                  <Select
                    placeholder="Placeholder"
                    allowClear
                    suffixIcon={
                      <NavArrowDown
                        width={20}
                        height={20}
                        className="text-gray-800"
                      />
                    }
                    size="large"
                  >
                    <Select.Option value="option1">Option 1</Select.Option>
                    <Select.Option value="option2">Option 2</Select.Option>
                    <Select.Option value="option3">Option 3</Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            }
          />

          <DocumentationCard
            code={group}
            subTitle="Field Group"
            content={
              <Form
                className="w-[26.3rem] flex items-center"
                layout="vertical"
                autoComplete="off"
              >
                <Form.Item
                  className="me-2"
                  name="name"
                  label="Label"
                  extra="Hint"
                >
                  <Select
                    placeholder="Placeholder"
                    defaultValue={"mr"}
                    allowClear
                    suffixIcon={
                      <NavArrowDown
                        width={20}
                        height={20}
                        className="text-gray-800"
                      />
                    }
                    size="large"
                  >
                    <Select.Option value="mr">Mr.</Select.Option>
                    <Select.Option value="mrs">Mrs.</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item className="w-full mt-2">
                  <Input size="large" placeholder="John Doe" className="mb-2" />
                </Form.Item>
              </Form>
            }
          />

          <DocumentationCard
            code={country}
            subTitle="Country Field"
            content={
              <Form className="w-96" layout="vertical" autoComplete="off">
                <Form.Item name="phone" label="Label" className="w-full">
                  <IntlTelInput preferredCountries={["gb"]} />
                </Form.Item>
              </Form>
            }
          />

          <DocumentationCard
            code={stepper}
            subTitle="Stepper"
            content={
              <Form className="w-96" layout="vertical" autoComplete="off">
                <Form.Item label="Label" extra="Hint">
                  <InputNumber
                    keyboard={true}
                    placeholder="Placeholder"
                    size="large"
                    className="w-full"
                  />
                </Form.Item>
              </Form>
            }
          />

          <DocumentationCard
            code={password}
            subTitle="Password Field"
            content={
              <Form className="w-96" layout="vertical" autoComplete="off">
                <Form.Item name="password" label="Label" extra="Hint">
                  <Input.Password
                    placeholder="Placeholder"
                    size="large"
                    iconRender={(visible) =>
                      visible ? (
                        <span>
                          <Eye width={24} height={24} />
                        </span>
                      ) : (
                        <span>
                          <EyeClosed width={24} height={24} />
                        </span>
                      )
                    }
                  />
                </Form.Item>
              </Form>
            }
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={formSpecsImg}
            column={true}
            title="Default"
            list={[
              {
                text: "Direction",
                value: "Vertical",
              },
              {
                text: "Alignment",
                value: "Top Left",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Hug",
              },
              {
                text: "Item Spacing",
                value: 8,
              },
              {
                text: "Padding left",
                value: 16,
              },
              {
                text: "Padding right",
                value: 16,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

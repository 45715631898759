export const base = `import React from "react";

import { Collapse } from "antd";
import { Heart, NavArrowDown } from "iconoir-react";

export default function Collapse() {
  const collapseItems = [
    {
      key: "1",
      showArrow: false,
      label: (
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-6">
            <Heart width={24} height={24} />

            <div className="flex flex-col gap-1">
              <span className="block text-p-md text-primaryA font-medium">
                Label
              </span>
              <p className="text-p-sm font-medium text-gray-800">Paragraph</p>
              <span className="text-p-sm font-medium block text-gray-600">
                Support paragraph
              </span>
            </div>
          </div>

          <NavArrowDown width={24} height={24} />
        </div>
      ),
      children: (
        <p className="text-p-xs text-gray-800 mb-2">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
          error, recusandae, quo impedit assumenda vero rem illo modi aperiam
          cum eaque? Ex, laboriosam et harum fugit explicabo delectus nam odio.
        </p>
      ),
    },
  ];

  const onChange = (key) => {
    console.log(key);
  };

  return (
    <div className="flex flex-col gap-4 py-10 w-full max-w-[375px]">
      <Collapse
        ghost
        items={collapseItems}
        defaultActiveKey={["1"]}
        onChange={onChange}
        className="default-collapse default-collapse-noradius"
      />

      <Collapse
        ghost
        items={collapseItems}
        defaultActiveKey={["1"]}
        onChange={onChange}
        className="default-collapse"
      />
    </div>
  );
}`;
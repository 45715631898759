export const base = `import React from "react";

import { Switch } from "antd";

export default function Switch() {
  return (
    <div className="flex items-center gap-14">
        <Switch />
        <Switch defaultChecked />
    </div>
  );
}`;
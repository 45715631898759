const Tabs = {
  horizontalItemPadding: "12px 16px",
  fontSize: 16,
  fontWeight: 500,
  colorText: "#7B8B95",
  itemSelectedColor: "#0D0F11",
  itemHoverColor: "#007FDF",
  inkBarColor: "#007FDF",
};

export default Tabs;

import React from "react";

import { Tabs } from "antd";
import { HeartSolid } from "iconoir-react";

import anatomy from "assets/img/documentation/tabs/tabs-anatomy.svg";
import specs from "assets/img/documentation/tabs/tabs-specs.svg";

import { base } from "./code";
import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

export default function DocumentationTabs() {
  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <div className="py-10 w-full max-w-[435px]">
                <Tabs
                  className="bg-white p-2 default-tab"
                  defaultActiveKey="1"
                  items={[0, 1].map((_, i) => {
                    const id = String(i + 1);
                    return {
                      label: (
                        <div className="flex items-center gap-2">
                          <HeartSolid width={18} height={18} />
                          Tab {id}
                        </div>
                      ),
                      key: id,
                      children: (
                        <p className="text-primaryA px-4 pb-4">Tab {id}</p>
                      ),
                    };
                  })}
                />

                <Tabs
                  className="bg-white mt-10 p-2"
                  onChange={onChange}
                  type="card"
                  items={new Array(3).fill(null).map((_, i) => {
                    const id = String(i + 1);
                    return {
                      label: "Tab " + id,
                      key: id,
                      children: (
                        <p className="text-primaryA px-4 pb-4">Tab {id}</p>
                      ),
                    };
                  })}
                />
              </div>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={
              <div className="bg-gray-50 rounded-lg">
                <img src={anatomy} alt="Modal" />
              </div>
            }
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={specs}
            title="Default"
            column={true}
            list={[
              {
                text: "Direction",
                value: "Horizontal",
              },
              {
                text: "Alignment",
                value: "Top left",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Fixed",
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

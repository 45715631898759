import React from "react";

import { Breadcrumb, Collapse } from "antd";
import { NavArrowDown, NavArrowRight } from "iconoir-react";

import cardIcon from "assets/img/card-icon.svg";

const collapseItems = [
  {
    key: "1",
    showArrow: false,
    label: (
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div
            className={`w-12 h-12 md:w-16 md:h-16 rounded-2xl flex items-center justify-center`}
            style={{ backgroundColor: "#16465A" }}
          >
            <img src={cardIcon} alt={"Compound AI"} className="w-3/5" />
          </div>

          <div>
            <span className="block text-p-xs md:text-p-md text-gray-400">
              Hypeople
            </span>
            <span className="block text-p-lg md:text-h4 font-medium text-primaryA">
              Merchantfy
            </span>
          </div>
        </div>
        <NavArrowDown width={24} height={24} />
      </div>
    ),
    children: (
      <div className="flex flex-col gap-2 mb-3 border-t border-gray-100 pt-4">
        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Hizmet Kodu
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            -
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Hizmet Paket Adı
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            21 Haziran 2023
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Hizmet Kullanım Ölçü Birimi
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            GB
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Hizmet Fiyat Tipi
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            Abonelik
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Birim Fiyat / Abonelik Fiyatı
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            120£
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Hizmet Fiyatı Döviz Cinsi
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            £
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Satın Alma Tarihi
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            21 Mayıs 2023
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Satın Alan Kullanıcı Adı Soyadı
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            Nergis Sadıklar
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Satın Alan Kullanıcı Tc Kimlik No
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            11112222333
          </span>
        </div>
      </div>
    ),
  },
  {
    key: "2",
    showArrow: false,
    label: (
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div
            className={`w-12 h-12 md:w-16 md:h-16 rounded-2xl flex items-center justify-center`}
            style={{ backgroundColor: "#16465A" }}
          >
            <img src={cardIcon} alt={"Compound AI"} className="w-3/5" />
          </div>

          <div>
            <span className="block text-p-xs md:text-p-md text-gray-400">
              Hypeople
            </span>
            <span className="block text-p-lg md:text-h4 font-medium text-primaryA">
              Merchantfy
            </span>
          </div>
        </div>
        <NavArrowDown width={24} height={24} />
      </div>
    ),
    children: (
      <div className="flex flex-col gap-2 mb-3 border-t border-gray-100 pt-4">
        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Hizmet Kodu
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            -
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Hizmet Paket Adı
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            21 Haziran 2023
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Hizmet Kullanım Ölçü Birimi
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            GB
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Hizmet Fiyat Tipi
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            Abonelik
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Birim Fiyat / Abonelik Fiyatı
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            120£
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Hizmet Fiyatı Döviz Cinsi
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            £
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Satın Alma Tarihi
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            21 Mayıs 2023
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Satın Alan Kullanıcı Adı Soyadı
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            Nergis Sadıklar
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Satın Alan Kullanıcı Tc Kimlik No
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            11112222333
          </span>
        </div>
      </div>
    ),
  },
  {
    key: "3",
    showArrow: false,
    label: (
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div
            className={`w-12 h-12 md:w-16 md:h-16 rounded-2xl flex items-center justify-center`}
            style={{ backgroundColor: "#16465A" }}
          >
            <img src={cardIcon} alt={"Compound AI"} className="w-3/5" />
          </div>

          <div>
            <span className="block text-p-xs md:text-p-md text-gray-400">
              Hypeople
            </span>
            <span className="block text-p-lg md:text-h4 font-medium text-primaryA">
              Merchantfy
            </span>
          </div>
        </div>
        <NavArrowDown width={24} height={24} />
      </div>
    ),
    children: (
      <div className="flex flex-col gap-2 mb-3 border-t border-gray-100 pt-4">
        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Hizmet Kodu
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            -
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Hizmet Paket Adı
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            21 Haziran 2023
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Hizmet Kullanım Ölçü Birimi
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            GB
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Hizmet Fiyat Tipi
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            Abonelik
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Birim Fiyat / Abonelik Fiyatı
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            120£
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Hizmet Fiyatı Döviz Cinsi
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            £
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Satın Alma Tarihi
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            21 Mayıs 2023
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Satın Alan Kullanıcı Adı Soyadı
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            Nergis Sadıklar
          </span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-xs md:text-label-lg font-medium">
            Satın Alan Kullanıcı Tc Kimlik No
          </span>
          <span className="text-primaryA text-p-xs md:text-label-lg font-medium">
            11112222333
          </span>
        </div>
      </div>
    ),
  },
];

export default function MySubscriptions() {
  return (
    <div className="page-container">
      <div className="mb-12 mt-12">
        <Breadcrumb
          separator={
            <NavArrowRight className="mt-0.5" width={18} height={18} />
          }
          items={[
            { title: "Hizmetler" },
            { title: "Sahip Olduğum Hizmetler" },
          ]}
        />
      </div>

      <div className="flex items-center gap-4">
        <h1 className="text-accent text-h5 md:text-h1 font-medium flex items-center gap-1 md:gap-4">
          Sahip Olduğum Hizmetler
        </h1>
        <span className="bg-blue-100 text-blue-600 text-p-xs md:text-p-xs rounded-full px-2 md:px-3 py-1">
          8 Hizmet
        </span>
      </div>

      <div className="mt-6 lg:mt-14">
        <Collapse
          bordered={false}
          items={collapseItems}
          ghost
          defaultActiveKey={["1"]}
        />
      </div>
    </div>
  );
}

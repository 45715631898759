export const base = `import React from "react";

import { Button, Space, message } from "antd";
import {
  CheckCircleSolid,
  InfoCircleSolid,
  WarningCircleSolid,
  XmarkCircleSolid,
} from "iconoir-react";

export default function Message() {
  const [messageApi, contextHolder] = message.useMessage();

  const noicon = () => {
    messageApi.open({
      content: "Snackbar with action and close affordance",
      style: {
        textAlign: "right",
      },
    });
  };

  const success = () => {
    messageApi.open({
      icon: <CheckCircleSolid width={24} height={24} className="text-white" />,
      content: "Snackbar with action and close affordance",
      style: {
        textAlign: "right",
      },
    });
  };

  const error = () => {
    messageApi.open({
      icon: <XmarkCircleSolid width={24} height={24} className="text-white" />,
      content: "Snackbar with action and close affordance",
      style: {
        textAlign: "right",
      },
    });
  };

  const warning = () => {
    messageApi.open({
      icon: (
        <WarningCircleSolid width={24} height={24} className="text-white" />
      ),
      content: "Snackbar with action and close affordance",
      style: {
        textAlign: "right",
      },
    });
  };

  const info = () => {
    messageApi.open({
      icon: <InfoCircleSolid width={24} height={24} className="text-white" />,
      content: "Snackbar with action and close affordance",
      style: {
        textAlign: "right",
      },
    });
  };

  const loading = () => {
    messageApi.open({
      type: "loading",
      content: "Snackbar with action and close affordance",
      style: {
        textAlign: "right",
      },
    });
  };

  return (
    <>
      {contextHolder}
      <Space>
        <Button type="primary" onClick={noicon}>
          Default
        </Button>
        <Button type="primary" onClick={success}>
          Success
        </Button>
        <Button type="primary" onClick={error}>
          Error
        </Button>
        <Button type="primary" onClick={warning}>
          Warning
        </Button>
        <Button type="primary" onClick={info}>
          Info
        </Button>
        <Button type="primary" onClick={loading}>
          Loading
        </Button>
      </Space>
    </>
  );
}`;
import { useState } from "react";
import { Link } from "react-router-dom";

import { Dropdown } from "antd";
import {
  DatabaseStats,
  GraphDown,
  LogOut,
  MultiplePagesEmpty,
  NavArrowDown,
  Percentage,
  ReportColumns,
  Reports,
  StatDown,
  StatsReport,
  Strategy,
  User,
  CloudBookmark,
  Menu,
  Xmark,
  NavArrowRight,
  ArrowLeft,
} from "iconoir-react";

import logo from "assets/img/logo.svg";

const menuItems = [
  {
    items: [
      {
        key: "1",
        label: (
          <Link to={"#"} className="flex gap-2">
            <GraphDown width={24} height={24} />

            <div>
              <span className="block text-p-md font-medium text-primaryA">
                Network Tools
              </span>
              <p className="mt-1 text-p-sm text-gray-400">
                These cases are perfectly simple and easy to distinguish
              </p>
            </div>
          </Link>
        ),
      },
      {
        key: "2",
        label: (
          <Link to={"#"} className="flex gap-2">
            <StatDown width={24} height={24} />

            <div>
              <span className="block text-p-md font-medium text-primaryA">
                Analytics
              </span>
              <p className="mt-1 text-p-sm text-gray-400">
                These cases are perfectly simple and easy to distinguish
              </p>
            </div>
          </Link>
        ),
      },
      {
        key: "3",
        label: (
          <Link to={"#"} className="flex gap-2">
            <Strategy width={24} height={24} />

            <div>
              <span className="block text-p-md font-medium text-primaryA">
                App for Retail
              </span>
              <p className="mt-1 text-p-sm text-gray-400">
                These cases are perfectly simple and easy to distinguish
              </p>
            </div>
          </Link>
        ),
      },
      {
        key: "4",
        label: (
          <Link to={"#"} className="flex gap-2">
            <DatabaseStats width={24} height={24} />

            <div>
              <span className="block text-p-md font-medium text-primaryA">
                Bankacılık ve Finans
              </span>
              <p className="mt-1 text-p-sm text-gray-400">
                These cases are perfectly simple and easy to distinguish
              </p>
            </div>
          </Link>
        ),
      },
      {
        key: "5",
        label: (
          <Link to={"#"} className="flex gap-2">
            <ReportColumns width={24} height={24} />

            <div>
              <span className="block text-p-md font-medium text-primaryA">
                App for Analytics AI
              </span>
              <p className="mt-1 text-p-sm text-gray-400">
                These cases are perfectly simple and easy to distinguish
              </p>
            </div>
          </Link>
        ),
      },
      {
        key: "6",
        label: (
          <Link to={"#"} className="flex gap-2">
            <Reports width={24} height={24} />

            <div>
              <span className="block text-p-md font-medium text-primaryA">
                Bankacılık ve Finansssasas
              </span>
              <p className="mt-1 text-p-sm text-gray-400">
                These cases are perfectly simple and easy to distinguish
              </p>
            </div>
          </Link>
        ),
      },
      {
        key: "7",
        label: (
          <Link to={"#"} className="flex gap-2">
            <User width={24} height={24} />

            <div>
              <span className="block text-p-md font-medium text-primaryA">
                Apps
              </span>
              <p className="mt-1 text-p-sm text-gray-400">
                These cases are perfectly simple and easy to distinguish
              </p>
            </div>
          </Link>
        ),
      },
      {
        key: "8",
        label: (
          <Link to={"#"} className="flex gap-2">
            <StatsReport width={24} height={24} />

            <div>
              <span className="block text-p-md font-medium text-primaryA">
                Bankacılık ve Finans
              </span>
              <p className="mt-1 text-p-sm text-gray-400">
                These cases are perfectly simple and easy to distinguish
              </p>
            </div>
          </Link>
        ),
      },
      {
        key: "9",
        label: (
          <Link to={"#"} className="flex gap-2">
            <Percentage width={24} height={24} />

            <div>
              <span className="block text-p-md font-medium text-primaryA">
                Framerwork
              </span>
              <p className="mt-1 text-p-sm text-gray-400">
                These cases are perfectly simple and easy to distinguish
              </p>
            </div>
          </Link>
        ),
      },
    ],
  },
  {
    items: [
      {
        key: "1",
        label: (
          <Link to={"/my-subscriptions"} className="flex gap-2">
            <CloudBookmark width={24} height={24} />

            <div>
              <span className="block text-p-md font-medium text-primaryA">
                Sahip Olduğum Hizmetler
              </span>
              <p className="mt-1 text-p-sm text-gray-400">
                These cases are perfectly simple and easy to distinguish
              </p>
            </div>
          </Link>
        ),
      },
    ],
  },
  {
    items: [
      {
        key: "1",
        label: (
          <Link to={"/payment-history"} className="flex gap-2">
            <MultiplePagesEmpty width={24} height={24} />

            <div>
              <span className="block text-p-md font-medium text-primaryA">
                Fatura Listesi
              </span>
              <p className="mt-1 text-p-sm text-gray-400">
                These cases are perfectly simple and easy to distinguish
              </p>
            </div>
          </Link>
        ),
      },
    ],
  },
];

const profileItems = [
  {
    key: "1",
    label: (
      <span className="text-primaryA text-label-md font-medium">
        Debi Çakar
      </span>
    ),
  },
  {
    key: "2",
    label: (
      <Link to={"#"} className="flex items-center justify-between">
        <span className="text-gray-600 text-p-md">Tc Kimlik Numarası</span>
        <span className="text-primaryA text-p-md font-medium">12345678901</span>
      </Link>
    ),
  },
  {
    key: "3",
    label: (
      <Link to={"#"} className="flex items-center justify-between">
        <span className="text-gray-600 text-p-md">Email Adresi</span>
        <span className="text-primaryA text-p-md font-medium">
          me@debi.design
        </span>
      </Link>
    ),
  },
  {
    key: "4",
    label: (
      <div className="border-t border-gray-100 -mx-5 px-5 pt-3 mt-1">
        <Link to={"/login"} className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <LogOut className="text-negative" width={24} height={24} />
            <span className="text-negative text-p-md font-medium">Çıkış Yap</span>
          </div>
        </Link>
      </div>
    ),
  },
];

export default function Header() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [mobileMenuIndex, setMobileMenuIndex] = useState(false);
  const [mobileMenuTitle, setMobileMenuTitle] = useState(false);
  const [profileMobileMenu, setProfileMobileMenu] = useState(false);

  function mobileMenuShow() {
    setMobileMenu(!mobileMenu);
    setProfileMobileMenu(false);

    if (!mobileMenu) {
      setMobileMenuIndex(false);
    }
  }

  function mobileMenuHandle(index, title) {
    setMobileMenuIndex(index);
    setMobileMenuTitle(title);
  }

  function profileMenuHandle() {
    setProfileMobileMenu(!profileMobileMenu);

    setMobileMenuIndex(false);
    setMobileMenu(false);
  }

  return (
    <header className="relative z-10 px-4 lg:px-12 py-3 md:py-4 border-b border-gray-100">
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-between md:justify-start flex-1 md:flex-none pr-6 md:pr-0 md:max-sm:bg-red-50 md:gap-12">
          <Link to={"/"} className="flex items-center gap-2 md:gap-4">
            <img src={logo} alt="Logo" className="w-10 md:w-auto" />
          </Link>

          <div className="hidden lg:block header-category-dropdown">
            <Dropdown
              menu={{
                items: menuItems[0].items,
              }}
              getPopupContainer={(e) =>
                document.querySelectorAll(".header-category-dropdown")[0]
              }
            >
              <div className="flex items-center md:gap-1 cursor-pointer">
                <span className="text-p-xs md:text-p-md !font-medium text-primaryA">
                  Kategoriler
                </span>
                <NavArrowDown />
              </div>
            </Dropdown>
          </div>

          <div className="hidden lg:block header-category-dropdown header-default-dropdown">
            <Dropdown
              menu={{
                items: menuItems[1].items,
              }}
              getPopupContainer={(e) =>
                document.querySelectorAll(".header-category-dropdown")[1]
              }
            >
              <div className="flex items-center md:gap-1 cursor-pointer">
                <span className="text-p-xs md:text-p-md !font-medium text-primaryA">
                  Hizmetlerim
                </span>
                <NavArrowDown />
              </div>
            </Dropdown>
          </div>

          <div className="hidden lg:block header-category-dropdown header-default-dropdown">
            <Dropdown
              menu={{
                items: menuItems[2].items,
              }}
              getPopupContainer={(e) =>
                document.querySelectorAll(".header-category-dropdown")[2]
              }
            >
              <div className="flex items-center md:gap-1 cursor-pointer">
                <span className="text-p-xs md:text-p-md !font-medium text-primaryA">
                  Faturalarım
                </span>
                <NavArrowDown />
              </div>
            </Dropdown>
          </div>
        </div>

        <div className="header-profile-dropdown flex items-center gap-4">
          {window.innerWidth >= 992 ? (
            <Dropdown
              menu={{
                items: profileItems,
              }}
              placement="bottomRight"
              getPopupContainer={(e) =>
                document.querySelector(".header-profile-dropdown")
              }
            >
              <div className="rounded-lg border border-gray-100 flex items-center px-3 py-2">
                <div className="min-w-[110px] lg:min-w-[240px]">
                  <span className="block text-neutral-100 text-p-sm font-medium">
                    Debi Çakar
                  </span>
                  <span className="block text-neutral-80 text-p-xs">
                    Fibabank
                  </span>
                </div>

                <NavArrowDown width={24} height={24} />
              </div>
            </Dropdown>
          ) : (
            <div
              onClick={() => profileMenuHandle()}
              className="rounded-lg border border-gray-100 flex items-center px-3 py-2"
            >
              <div className="min-w-[110px] lg:min-w-[240px]">
                <span className="block text-neutral-100 text-p-sm font-medium">
                  Debi Çakar
                </span>
                <span className="block text-neutral-80 text-p-xs">
                  Fibabank
                </span>
              </div>

              {!profileMobileMenu ? (
                <NavArrowDown width={24} height={24} />
              ) : (
                <Xmark width={24} height={24} />
              )}
            </div>
          )}

          <div className="block lg:hidden" onClick={() => mobileMenuShow()}>
            {mobileMenu ? (
              <Xmark width={24} height={24} />
            ) : (
              <Menu width={24} height={24} />
            )}
          </div>
        </div>
      </div>

      {mobileMenu && (
        <div className="mobile-menu overflow-auto fixed top-[91px] left-0 right-0 bottom-0 bg-white py-2 px-4">
          {!mobileMenuIndex ? (
            <>
              <div
                className="flex justify-between items-center md:gap-1 py-4 px-3"
                onClick={() => mobileMenuHandle(1, "Kategoriler")}
              >
                <span className="text-p-md !font-medium text-primaryA">
                  Kategoriler
                </span>
                <NavArrowRight />
              </div>

              <div
                className="flex justify-between items-center md:gap-1 py-4 px-3"
                onClick={() => mobileMenuHandle(2, "Hizmetlerim")}
              >
                <span className="text-p-md !font-medium text-primaryA">
                  Hizmetlerim
                </span>
                <NavArrowRight />
              </div>

              <div
                className="flex justify-between items-center md:gap-1 py-4 px-3"
                onClick={() => mobileMenuHandle(3, "Faturalarım")}
              >
                <span className="text-p-md !font-medium text-primaryA">
                  Faturalarım
                </span>
                <NavArrowRight />
              </div>
            </>
          ) : (
            <div className="-my-2">
              <div
                className="flex items-center gap-3 py-2 pb-4 border-b border-gray-100"
                onClick={() => mobileMenuHandle(false)}
              >
                <ArrowLeft width={18} />
                <span className="text-p-md !font-medium text-primaryA">
                  {mobileMenuTitle}
                </span>
              </div>

              <div className="px-2 py-5 flex flex-col gap-8">
                {menuItems[mobileMenuIndex - 1].items.map((item, index) => (
                  <div key={index}>{item.label}</div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {profileMobileMenu && (
        <div className="profile-mobile-menu overflow-auto fixed top-[91px] left-0 right-0 bottom-0 bg-white py-2 px-4">
          <div className="-my-2">
            <div className="px-2 py-5 flex flex-col gap-3">
              {profileItems.map((item, index) => (
                <div key={index}>{item.label}</div>
              ))}
            </div>
          </div>
        </div>
      )}
    </header>
  );
}

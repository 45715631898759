import { useRef, useState } from "react";

import { CheckCircleSolid, Code, Copy } from "iconoir-react";

import { message } from "antd";

export default function DocumentationCard({
  code,
  content,
  title,
  subTitle,
  desc,
  column,
}) {
  const [messageApi, contextHolder] = message.useMessage();
  const [codeShow, setCodeShow] = useState(false);
  const codeRef = useRef(null);

  function handleCopy() {
    messageApi.open({
      icon: <CheckCircleSolid width={24} height={24} className="text-white" />,
      content: "Copied",
    });

    navigator.clipboard.writeText(codeRef.current.innerText);
  }

  return (
    <div className="mb-12">
      {title && (
        <div className="text-h3 font-medium mb-6 text-primaryA">{title}</div>
      )}
      {subTitle && <div className="text-gray-600 text-h6 mb-4">{subTitle}</div>}
      {desc && <p className="text-p-md text-gray-600">{desc}</p>}

      <div
        className={`relative w-full flex ${
          column
            ? "flex-col items-start justify-start p-6"
            : "items-center justify-center"
        } bg-gray-50 gap-6 ${codeShow ? "rounded-t-lg" : "rounded-lg"}`}
      >
        {code && (
          <div
            onClick={() => setCodeShow(!codeShow)}
            className="cursor-pointer absolute top-0 right-0 p-2 bg-gray-100 rounded-tr-lg rounded-bl-lg"
          >
            <Code className="text-black" />
          </div>
        )}

        <div
          className={`${
            code ? "py-20" : ""
          } px-4 w-full min-h-[260px] overflow-auto flex flex-col items-center justify-center`}
        >
          {content}
        </div>
      </div>

      {codeShow && (
        <div className="relative bg-gray-100 text-blue-900 pt-14 py-10 px-10 whitespace-nowrap rounded-b-lg overflow-auto">
          <div
            onClick={handleCopy}
            className="cursor-pointer absolute top-0 right-0 p-2 bg-gray-50 rounded-bl-lg"
          >
            <Copy className="text-black" />
          </div>

          <code>
            {contextHolder}
            <pre ref={codeRef}>{code}</pre>
          </code>
        </div>
      )}
    </div>
  );
}

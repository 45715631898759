export const base = `import React from "react";

import { DatePicker } from "antd";

export default function DatePicker() {
  return (
    <div className="flex gap-2 items-end">
        <div>
            <span className="block text-p-xs text-primaryA font-medium mb-1">
                Start Date
            </span>
            <DatePicker placeholder={format} format={format} suffixIcon={false} />
        </div>

        <div>
            <span className="block text-p-xs text-primaryA font-medium mb-1">
                End Date
            </span>
            <DatePicker placeholder={format} format={format} suffixIcon={false} />
        </div>

        <div className="relative">
            <Calendar
                width={18}
                height={18}
                strokeWidth={2}
                className="text-gray-400 absolute top-2/4 -translate-y-2/4 left-4 z-10"
            />
            <DatePicker
                className="w-[80px] date-picker-select"
                format={format}
                placeholder={false}
                suffixIcon={
                    <NavArrowDown
                    width={18}
                    height={18}
                    strokeWidth={2}
                    className="text-gray-400"
                    />
                }
            />
        </div>
    </div>
  );
}`;
import React from "react";

import { Link } from "react-router-dom";

function DocumentationTextCard({ list }) {
  return (
    <div className="w-full">
      {list.map((item, index) => (
        <div key={index} className="flex justify-between mb-2">
          <div className="text-p-md text-gray-600">{item.text + ":"}</div>
          <div className="text-p-md text-primaryA">{item.value}</div>   
          {item.link && <Link className="text-p-md text-primaryA" to={item.URL} target="_blank">{item.URL}</Link>}
        </div>
      ))}
    </div>
  );
}

export default DocumentationTextCard;

import React from "react";

import { Button, Result, Tabs } from "antd";
import {
  CheckCircleSolid,
  InfoCircleSolid,
  WarningTriangleSolid,
  XmarkCircleSolid,
} from "iconoir-react";

import anatomy from "assets/img/documentation/result/result-anatomy.svg";
import specs from "assets/img/documentation/result/result-specs.svg";

import { base } from "./code";
import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

export default function DocumentationResult() {
  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <div>
                <Result
                  icon={false}
                  title={
                    <span className="block font-semibold text-p-lg text-primaryA">
                      Single line headline
                    </span>
                  }
                  subTitle={
                    <p className="text-p-md text-gray-600 max-w-[300px]">
                      Proin ut dui sed metus pharetra hend rerit vel non mi.
                      Nulla ornare faucibus.
                    </p>
                  }
                  extra={[
                    <Button key={1}>Label</Button>,
                    <Button key={2} type="primary">Label</Button>,
                  ]}
                />

                <Result
                  icon={
                    <div className="flex justify-center">
                      <XmarkCircleSolid
                        width={64}
                        height={64}
                        className="text-negative"
                      />
                    </div>
                  }
                  title={
                    <span className="block font-semibold text-p-lg text-primaryA">
                      Single line headline
                    </span>
                  }
                  subTitle={
                    <p className="text-p-md text-gray-600 max-w-[300px]">
                      Proin ut dui sed metus pharetra hend rerit vel non mi.
                      Nulla ornare faucibus.
                    </p>
                  }
                  extra={[
                    <Button key={1}>Label</Button>,
                    <Button key={2} danger>Label</Button>,
                  ]}
                />

                <Result
                  icon={
                    <div className="flex justify-center">
                      <WarningTriangleSolid
                        width={64}
                        height={64}
                        className="text-warning"
                      />
                    </div>
                  }
                  title={
                    <span className="block font-semibold text-p-lg text-primaryA">
                      Single line headline
                    </span>
                  }
                  subTitle={
                    <p className="text-p-md text-gray-600 max-w-[300px]">
                      Proin ut dui sed metus pharetra hend rerit vel non mi.
                      Nulla ornare faucibus.
                    </p>
                  }
                  extra={[
                    <Button key={1}>Label</Button>,
                    <Button key={2} type="primary">Label</Button>,
                  ]}
                />

                <Result
                  icon={
                    <div className="flex justify-center">
                      <CheckCircleSolid
                        width={64}
                        height={64}
                        className="text-positive"
                      />
                    </div>
                  }
                  title={
                    <span className="block font-semibold text-p-lg text-primaryA">
                      Single line headline
                    </span>
                  }
                  subTitle={
                    <p className="text-p-md text-gray-600 max-w-[300px]">
                      Proin ut dui sed metus pharetra hend rerit vel non mi.
                      Nulla ornare faucibus.
                    </p>
                  }
                  extra={[
                    <Button key={1}>Label</Button>,
                    <Button key={2} type="success">Label</Button>,
                  ]}
                />

                <Result
                  icon={
                    <div className="flex justify-center">
                      <InfoCircleSolid
                        width={64}
                        height={64}
                        className="text-blue-600"
                      />
                    </div>
                  }
                  title={
                    <span className="block font-semibold text-p-lg text-primaryA">
                      Single line headline
                    </span>
                  }
                  subTitle={
                    <p className="text-p-md text-gray-600 max-w-[300px]">
                      Proin ut dui sed metus pharetra hend rerit vel non mi.
                      Nulla ornare faucibus.
                    </p>
                  }
                  extra={[
                    <Button key={1}>Label</Button>,
                    <Button key={2} type="primary">Label</Button>,
                  ]}
                />
              </div>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={
              <div className="bg-gray-50 rounded-lg">
                <img src={anatomy} alt="Modal" />
              </div>
            }
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={specs}
            title="Success"
            column={true}
            list={[
              {
                text: "Direction",
                value: "Horizantal",
              },
              {
                text: "Alignment",
                value: "Middle left",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Hug",
              },
              {
                text: "Padding",
                value: "36",
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

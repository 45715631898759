import React from "react";

import { Tabs } from "antd";

import DocumentationCard from "components/DocumentationCard";

import { typography } from "./code";

const items = [
  {
    key: "1",
    label: "Overview",
    children: (
      <DocumentationCard
        column
        code={typography}
        content={
          <div className="w-full">
            <div className="mb-16">
              <h5 className="text-h5 text-primaryA font-medium mb-8">
                Paragraf
              </h5>

              <p className="text-p-xs text-black mb-8">
                Almost before we knew it, we had left the ground.
              </p>

              <p className="text-p-sm text-black mb-8">
                Almost before we knew it, we had left the ground.
              </p>

              <p className="text-p-md text-black mb-8">
                Almost before we knew it, we had left the ground.
              </p>

              <p className="text-p-lg text-black">
                Almost before we knew it, we had left the ground.
              </p>
            </div>

            <div className="mb-16">
              <h5 className="text-h5 text-primaryA font-medium mb-8">
                Label
              </h5>

              <p className="text-label-xs text-black mb-8">
                Almost be fore we knew it, we had left the ground.
              </p>

              <p className="text-label-sm text-black mb-8">
                Almost before we knew it, we had left the ground.
              </p>

              <p className="text-label-md text-black mb-8">
                Almost before we knew it, we had left the ground.
              </p>

              <p className="text-label-lg text-black">
                Almost before we knew it, we had left the ground.
              </p>
            </div>

            <div className="mb-16">
              <h5 className="text-h5 text-primaryA font-medium mb-8">
                Heading
              </h5>

              <h6 className="text-h6 text-black mb-8">
                Almost be fore we knew it, we had left the ground.
              </h6>

              <h5 className="text-h5 text-black mb-8">
                Almost before we knew it, we had left the ground.
              </h5>

              <h4 className="text-h4 text-black mb-8">
                Almost before we knew it, we had left the ground.
              </h4>

              <h3 className="text-h3 text-black mb-8">
                Almost before we knew it, we had left the ground.
              </h3>

              <h1 className="text-h2 text-black mb-8">
                Almost before we knew it, we had left the ground.
              </h1>

              <h1 className="text-h1 text-black">
                Almost before we knew it, we had left the ground.
              </h1>
            </div>

            <div>
              <h5 className="text-h5 text-primaryA font-medium mb-8">
                Display
              </h5>

              <div className="text-display-xs text-black mb-8">
                Almost be fore we knew it, we had left the ground.
              </div>

              <div className="text-display-sm text-black mb-8">
                Almost before we knew it, we had left the ground.
              </div>

              <div className="text-display-md text-black mb-8">
                Almost before we knew it, we had left the ground.
              </div>

              <div className="text-display-lg text-black">
                Almost before we knew it, we had left the ground.
              </div>
            </div>
          </div>
        }
      />
    ),
  },
];

export default function DocumentationTypography() {
  return <Tabs defaultActiveKey="1" items={items} />;
}

import React from "react";

import { Breadcrumb, Button } from "antd";
import { NavArrowRight } from "iconoir-react";

import companyLogo from "assets/img/product-detail-logo.svg";

function PaymentHistoryMonth() {
  return (
    <div className="page-container">
      <div className="mb-12 mt-12">
        <Breadcrumb
          separator={
            <NavArrowRight className="mt-0.5" width={18} height={18} />
          }
          items={[
            { title: "Hizmetlerim" },
            { title: "Compound AI" },
            { title: "Fatura Listesi" },
            { title: "Haziran 2023" },
          ]}
        />
      </div>

      <div className="mb-8">
        <div className="flex items-center justify-between border border-gray-100 rounded-2xl p-4 mb-11">
          <div className="flex items-center gap-4">
            <img src={companyLogo} alt="Compound AI" />
            <div>
              <span className="block text-p-md text-gray-600">Hypeople</span>
              <span className="block text-p-lg md:text-xl font-medium text-primaryA">
                Compound AI
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2 mb-3">
        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-sm md:text-label-lg">Fatura Dönemi</span>
          <span className="text-gray-800 text-p-sm md:text-label-lg">Haziran 2023</span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-sm md:text-label-lg">Fatura Tutarı</span>
          <span className="text-gray-800 text-p-sm md:text-label-lg">-</span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-sm md:text-label-lg">Fatura Tarihi</span>
          <span className="text-gray-800 text-p-sm md:text-label-lg">21 Haziran 2023</span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-sm md:text-label-lg">Ödeme Tarihi</span>
          <span className="text-gray-800 text-p-sm md:text-label-lg">21 Haziran 2023</span>
        </div>

        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-sm md:text-label-lg font-medium">
            Hizmet Kodu
          </span>
          <span className="text-gray-800 text-p-sm md:text-label-lg font-medium">
            298313
          </span>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-gray-400 text-p-sm md:text-label-lg font-medium">
            Fatura Numarası
          </span>
          <span className="text-gray-800 text-p-sm md:text-label-lg font-medium">
            123143232
          </span>
        </div>
      </div>

      <div className="flex justify-end mt-8">
        <Button size="large">Fatura PDF Görüntüle</Button>
      </div>
    </div>
  );
}

export default PaymentHistoryMonth;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, } from "react-router-dom";

import "assets/css/App.css";

import { ConfigProvider } from 'antd';

import allComponentsOverrides from 'components/overrides/index'

// Layouts
import FullLayout from 'layouts/layout/FullLayout';
import DefaultLayout from 'layouts/layout/DefaultLayout';
import DocumentationLayout from 'layouts/layout/DocumentationLayout';

// Components
import InputPage from 'pages/components-page/InputPage';
import ButtonPage from 'pages/components-page/ButtonPage';

// Pages
import App from 'pages/App';
import Login from "pages/Login"
import ProductList from 'pages/ProductList';
import ProductDetail from 'pages/ProductDetail';
import VendorDetail from 'pages/VendorDetail';
import Checkout from 'pages/Checkout';
import MySubscriptions from 'pages/MySubscriptions';
import PaymentHistory from 'pages/PaymentHistory';
import PaymentHistoryMonth from 'pages/PaymentHistoryMonth';

// Documentation
import DocumentationButton from 'pages/documentation/general/button/DocumentationButton';
import DocumentationIcons from 'pages/documentation/general/icons/DocumentationIcons';
import DocumentationTypography from 'pages/documentation/general/typography/DocumentationTypography';
import DocumentationBreadcrumb from 'pages/documentation/navigation/breadcrumb/DocumentationBreadcrumb';
import DocumentationSteps from 'pages/documentation/navigation/steps/DocumentationSteps';
import DocumentationSkelaton from 'pages/documentation/feedback/skelaton/DocumentationSkelaton';
import DocumentationModal from 'pages/documentation/feedback/modal/DocumentationModal';
import DocumentationResult from 'pages/documentation/feedback/result/DocumentationResult';
import DocumentationMessage from 'pages/documentation/feedback/message/DocumentationMessage';
import DocumentationAlert from 'pages/documentation/feedback/alert/DocumentationAlert';
import DocumentationCalendar from 'pages/documentation/data-display/calendar/DocumentationCalendar';
import DocumentationCollapse from 'pages/documentation/data-display/collapse/DocumentationCollapse';
import DocumentationTabs from 'pages/documentation/data-display/tabs/DocumentationTabs';
import DocumentationBadge from 'pages/documentation/data-display/badge/DocumentationBadge';
import DocumentationTag from 'pages/documentation/data-display/tag/DocumentationTag';
import DocumentationAvatar from 'pages/documentation/data-display/avatar/DocumentationAvatar';
import DocumentationTooltip from 'pages/documentation/data-display/tooltip/DocumentationTooltip';
import DocumentationUpload from 'pages/documentation/data-entry/upload/DocumentationUpload';
import DocumentationMenu from 'pages/documentation/navigation/menu/DocumentationMenu';
import DocumentationPagination from 'pages/documentation/navigation/pagination/DocumentationPagination';
import DocumentationSwitch from 'pages/documentation/data-entry/switch/DocumentationSwitch';
import DocumentationDatePicker from 'pages/documentation/data-entry/datepicker/DocumentationDatePicker';
import DocumentationTimePicker from 'pages/documentation/data-entry/timepicker/DocumentationTimePicker';
import DocumentationForm from 'pages/documentation/data-entry/form/DocumentationForm';
import DocumentationSelect from 'pages/documentation/data-entry/select/DocumentationSelect';
import DocumentationRadio from 'pages/documentation/data-entry/radio/DocumentationRadio';
import DocumentationCheckbox from 'pages/documentation/data-entry/checkbox/DocumentationCheckbox';
import DocumentationSlider from 'pages/documentation/data-entry/slider/DocumentationSlider';
import DocumentationInput from 'pages/documentation/data-entry/input/DocumentationInput';
import DocumentationInputNumber from 'pages/documentation/data-entry/inputnumber/DocumentationInputNumber';
import Error403 from 'pages/errors-page/Error403';
import Error404 from 'pages/errors-page/Error404';
import Error500 from 'pages/errors-page/Error500';

const router = createBrowserRouter([
  {
    path: "/",
    errorElement: <span>404</span>,
    children: [
      {
        path: "/",
        element:
          <DefaultLayout>
            <App />
          </DefaultLayout>,
      },
      {
        path: "/403",
        element:
          <FullLayout>
            <Error403 />
          </FullLayout>,
      },
      {
        path: "/404",
        element:
          <FullLayout>
            <Error404 />
          </FullLayout>,
      },
      {
        path: "/500",
        element:
          <FullLayout>
            <Error500 />
          </FullLayout>,
      },
      {
        path: "/documentation",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Button"
              },
            ]}
            title="Button"
            desc="Buttons are essential components in our design system, allowing users to initiate actions or commands when clicked or tapped. They serve as interactive elements with labels or text descriptions that convey the purpose of each action.
          ">
            <DocumentationButton />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/upload",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Upload"
              },
            ]}
            title="Upload"
            desc="Upload the file by selecting or dragging.
      ">
            <DocumentationUpload />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/steps",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Steps"
              },
            ]}
            title="Steps"
            desc="Steps is a navigation bar that guides users through the steps of a task.
      ">
            <DocumentationSteps />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/breadcrumb",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Breadcrumb"
              },
            ]}
            title="Breadcrumb"
            desc="A breadcrumb is a series of links that helps users see where they are within a website or app. It allows users to easily go back to a previous page or move up in the navigation.">
            <DocumentationBreadcrumb />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/typography",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Typography"
              },
            ]}
            title="Typography"
            desc="Basic text writing, including headings, body text, lists, and more.">
            <DocumentationTypography />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/icons",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Icons"
              },
            ]}
            title="Icons"
            desc="Semantic vector graphics.">
            <DocumentationIcons />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/button",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Button"
              },
            ]}
            title="Button"
            desc="Buttons are essential components in our design system, allowing users to initiate actions or commands when clicked or tapped. They serve as interactive elements with labels or text descriptions that convey the purpose of each action.
          ">
            <DocumentationButton />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/skelaton",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Skelaton"
              },
            ]}
            title="Skelaton"
            desc="Provide a placeholder while you wait for content to load, or to visualize content that doesn't exist yet.">
            <DocumentationSkelaton />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/modal",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Modal"
              },
            ]}
            title="Modal"
            desc="Modal dialogs.">
            <DocumentationModal />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/result",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Result"
              },
            ]}
            title="Result"
            desc="Used to feed back the results of a series of operational tasks.">
            <DocumentationResult />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/message",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Message"
              },
            ]}
            title="Message"
            desc="Display global messages as feedback in response to user operations.">
            <DocumentationMessage />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/alert",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Alert"
              },
            ]}
            title="Alert"
            desc="Alert component for feedback.">
            <DocumentationAlert />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/calendar",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Calendar"
              },
            ]}
            title="Calendar"
            desc="Container for displaying data in calendar form.">
            <DocumentationCalendar />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/collapse",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Collapse"
              },
            ]}
            title="Collapse"
            desc="A content area which can be collapsed and expanded.">
            <DocumentationCollapse />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/tabs",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Tabs"
              },
            ]}
            title="Tabs"
            desc="Tabs make it easy to switch between different views.">
            <DocumentationTabs />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/badge",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Badge"
              },
            ]}
            title="Badge"
            desc="Small numerical value or status descriptor for UI elements.">
            <DocumentationBadge />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/tag",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Tag"
              },
            ]}
            title="Tag"
            desc="Tag for categorizing or markup.">
            <DocumentationTag />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/avatar",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Avatar"
              },
            ]}
            title="Avatar"
            desc="Avatars can be used to represent people or objects. It supports images, Icons, or letters.">
            <DocumentationAvatar />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/tooltip",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Tooltip"
              },
            ]}
            title="Tooltip"
            desc="Tooltips are short informative messages that introduce new content or features and offer brief step-by-step guidance, appearing briefly or until the user closes them.">
            <DocumentationTooltip />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/menu",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Menu"
              },
            ]}
            title="Menu"
            desc="A versatile menu for navigation.
      ">
            <DocumentationMenu />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/pagination",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Pagination"
              },
            ]}
            title="Pagination"
            desc="A long list can be divided into several pages using Pagination, and only one page will be loaded at a time.
      ">
            <DocumentationPagination />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/switch",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Switch"
              },
            ]}
            title="Switch"
            desc="A switch is a control element used to quickly switch a setting to an open or closed state. 
      ">
            <DocumentationSwitch />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/slider",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Slider"
              },
            ]}
            title="Slider"
            desc="A Slider component for displaying current value and intervals in range.
      ">
            <DocumentationSlider />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/input",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Input"
              },
            ]}
            title="Input"
            desc="A basic widget for getting the user input is a text field. Keyboard and mouse can be used for providing or changing data.
      ">
            <DocumentationInput />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/input-number",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Input Number"
              },
            ]}
            title="Input Number"
            desc="A basic widget for getting the user input is a text field. Keyboard and mouse can be used for providing or changing data.">
            <DocumentationInputNumber />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/checkbox",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Checkbox"
              },
            ]}
            title="Checkbox"
            desc="Checkbox component.
      ">
            <DocumentationCheckbox />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/radio",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Radio Button"
              },
            ]}
            title="Radio Button"
            desc="A radio input allows users to select only one option from a number of choices. Radio is generally displayed in a radio group.
      ">
            <DocumentationRadio />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/select",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Select"
              },
            ]}
            title="Select"
            desc="Select component to select value from options.
      ">
            <DocumentationSelect />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/form",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Form"
              },
            ]}
            title="Form"
            desc="A text field is an input that allows a user to write or edit text.
      ">
            <DocumentationForm />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/time-picker",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Time Picker"
              },
            ]}
            title="Time Picker"
            desc="To select/input a time. 
      ">
            <DocumentationTimePicker />
          </DocumentationLayout>,
      },
      {
        path: "/documentation/date-picker",
        element:
          <DocumentationLayout
            breadcrumb={[
              {
                title: "Components"
              },
              {
                title: "Date Picker"
              },
            ]}
            title="Date Picker"
            desc="To select or input a date. 
      ">
            <DocumentationDatePicker />
          </DocumentationLayout>,
      },
      {
        path: "/checkout",
        element:
          <DefaultLayout>
            <Checkout />
          </DefaultLayout>,
      },
      {
        path: "/my-subscriptions",
        element:
          <DefaultLayout>
            <MySubscriptions />
          </DefaultLayout>,
      },
      {
        path: "/payment-history",
        element:
          <DefaultLayout>
            <PaymentHistory />
          </DefaultLayout>,
      },
      {
        path: "/payment-history-month",
        element:
          <DefaultLayout>
            <PaymentHistoryMonth />
          </DefaultLayout>,
      },
      {
        path: "/product-list",
        element:
          <DefaultLayout>
            <ProductList />
          </DefaultLayout>,
      },
      {
        path: "/product-detail",
        element:
          <DefaultLayout>
            <ProductDetail />
          </DefaultLayout>,
      },
      {
        path: "/vendor-detail",
        element:
          <DefaultLayout>
            <VendorDetail />
          </DefaultLayout>,
      },
      {
        path: "/login",
        element:
          <FullLayout>
            <Login />
          </FullLayout>,
      },
      {
        path: "/components-page/button",
        element:
          <FullLayout>
            <ButtonPage />
          </FullLayout>,
      },
      {
        path: "/components-page/input",
        element:
          <FullLayout>
            <InputPage />
          </FullLayout>,
      },
    ]
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#002748',
          red1: '#FFDDE4',
          red7: '#B00426',
          orange1: '#FFF3D3',
          orange7: '#A0450C',
          gold1: '#FEF9C3',
          gold7: '#854D0E',
          green1: '#D3F8DF',
          green7: '#0B8E51',
          blue1: '#DCF1FF',
          blue7: '#007FDF',
          purple1: '#F1E7FF',
          purple7: '#852AF3',
          magenta1: '#FDE6FF',
          magenta7: '#E016E3',
        },
        components: allComponentsOverrides
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  </React.StrictMode>
);
import React from "react";

export default function DocumentationSpecsCard({ img, title, list, column }) {
  return (
    <div className={`mb-12 flex ${column ? "flex-col-reverse" : ''} gap-6`}>
      <div
        className={`w-full min-h-[260px] ${
          column ? "" : "max-w-[425px]"
        } h-full flex items-center justify-center bg-gray-50 gap-6 rounded-lg`}
      >
        <img src={img} alt="" />
      </div>

      <div>
        <div className="text-h6 mb-4 text-primaryA">{title}</div>

        {list.map((item, index) => (
          <div key={index} className="text-p-sm text-gray-600 mb-2">
            {item.text}
            {item.value && ": " + item.value}
          </div>
        ))}
      </div>
    </div>
  );
}

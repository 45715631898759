import React from "react";

import { Tabs, Switch } from "antd";

import { base } from "./code";

import switchAnatomyImg from "assets/img/documentation/switch/switch-anatomy.png";
import switchSpecsImg from "assets/img/documentation/switch/switch-specs.png";

import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

export default function DocumentationSwitch() {
  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <div className="flex items-center gap-14">
                <Switch />
                <Switch defaultChecked />
              </div>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={<img src={switchAnatomyImg} alt="Anatomy"></img>}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={switchSpecsImg}
            title="Default"
            column={true}
            list={[
              {
                text: "Direction",
                value: "Horizontal",
              },
              {
                text: "Alignment",
                value: "Middle center",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Hug",
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

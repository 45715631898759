import React from "react";

import { Avatar, Tabs } from "antd";
import { User } from "iconoir-react";

import img from "assets/img/documentation/avatar/avatar-img.png";
import anatomy from "assets/img/documentation/avatar/avatar-anatomy.svg";
import specs from "assets/img/documentation/avatar/avatar-specs.svg";

import { base } from "./code";
import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

export default function DocumentationAvatar() {
  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <div className="flex flex-wrap items-center justify-center gap-12">
                <Avatar size={40} className="bg-accent font-bold">
                  AB
                </Avatar>
                <Avatar size={40} icon={<User />} className="bg-black" />
                <Avatar size={40} src={img} />
              </div>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={
              <div className="bg-gray-50 rounded-lg">
                <img src={anatomy} alt="Avatar" />
              </div>
            }
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={specs}
            title="Generic Letters"
            column={true}
            list={[
              {
                text: "Direction",
                value: "Horizontal",
              },
              {
                text: "Alignment",
                value: "Middle",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Hug",
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

export const base = `import React from "react";

import { Checkbox } from "antd";

export default function Checkbox() {
  return (
    <>
        <Checkbox />
        <Checkbox checked />
        <Checkbox disabled />
        <Checkbox checked disabled />
    </>
  );
}`;
export const base = `import React from "react";

import { InputNumber } from "antd";

export default function InputNumber() {
    return (
        <InputNumber size="large" placeholder="Placeholder" />
    );
}`;

export const handle = `import React from "react";

import { Form, InputNumber } from "antd";
import { CheckCircleSolid, WarningCircleSolid } from "iconoir-react";

export default function InputNumber() {
    return (
        <div className="flex flex-col gap-12 w-[32.5rem]">
            <InputNumber
                placeholder="Enable"
                size="large"
                className="w-full"
            />

            <Form.Item className="mb-0" validateStatus="error">
                <InputNumber
                    placeholder="Error"
                    defaultValue="Error"
                    size="large"
                    className="w-full"
                />
            </Form.Item>

            <Form.Item className="mb-0" validateStatus="success">
                <InputNumber
                    placeholder="Success"
                    defaultValue="Success"
                    size="large"
                    className="w-full"
                />
            </Form.Item>

            <InputNumber
                size="large"
                disabled
                placeholder="Disable"
                className="w-full"
            />
        </div>
    );
}`;

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom/dist";

import { Input } from "antd";

import logo from "assets/img/logo.svg";

import { menu } from "./documentationMenu";
import PageBreadcrumb from "components/PageBreadcrumb";
import { Menu, Search, Xmark } from "iconoir-react";

const DocumentationLayout = ({ title, desc, breadcrumb, children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  //--

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredMenu = menu.reduce((acc, category) => {
    const filteredList = category.list.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filteredList.length !== 0) {
      acc.push({
        title: category.title,
        list: filteredList,
      });
    }

    return acc;
  }, []);

  //--

  const [searchShow, setSearchShow] = useState(false);
  const [menuShow, setMenuShow] = useState(false);

  function searchShowHandle() {
    setMenuShow(false);
    setSearchShow(!searchShow);
  }

  function menuShowHandle() {
    setSearchShow(false);
    setMenuShow(!menuShow);
  }

  function menuItemHandle() {
    setSearchTerm("");
    setMenuShow(false);
    setSearchShow(false);
  }

  return (
    <>
      <header className="px-7 py-3 md:py-4 border-b border-gray-100">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between md:justify-start md:flex-none pr-6 md:pr-0 md:max-sm:bg-red-50 md:gap-12 md:basis-1/6">
            <Link
              to={"/"}
              className="flex items-center gap-2 md:gap-4 xl:mr-40"
            >
              <img src={logo} alt="Logo" width={60} />
            </Link>

            <Input
              size="large"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
              className={`${
                !searchShow && "hidden"
              } ml-2 md:ml-0 md:w-[300px] lg:w-[470px] lg:flex`}
              prefix={
                <Search
                  width={20}
                  height={20}
                  className="hidden md:inline-block mr-2"
                />
              }
            />
          </div>

          <Link
            className="hidden lg:inline-block text-primaryA text-label-md"
            to={"#"}
          >
            Components
          </Link>

          <div className="flex items-center gap-4 lg:hidden">
            {searchShow ? (
              <Xmark width={24} height={24} onClick={searchShowHandle} />
            ) : (
              <Search width={24} height={24} onClick={searchShowHandle} />
            )}

            {menuShow ? (
              <Xmark width={24} height={24} onClick={menuShowHandle} />
            ) : (
              <Menu width={24} height={24} onClick={menuShowHandle} />
            )}
          </div>
        </div>
      </header>

      <div className="flex">
        <div
          className={`${
            !menuShow && "hidden"
          } lg:block fixed top-0 bottom-0 lg:sticky lg:top-0 z-20 lg:h-screen w-[200px] lg:w-[150px] xl:w-[240px] bg-white border-e border-gray-100 overflow-y-auto overscroll-contain`}
        >
          <ul className="p-4">
            {filteredMenu.map((item, index) => (
              <div key={index}>
                {index > 0 && <hr className="my-2 border-gray-100" />}

                <div className="text-gray-400 text-label-xs uppercase ms-2 mb-2">
                  {item.title}
                </div>

                {item.list.map((menuItem, menuIndex) => (
                  <li key={menuIndex}>
                    <Link
                      to={menuItem.url}
                      className={`hover:bg-blue-50 ${
                        menuItem.url === window.location.pathname
                          ? "bg-blue-50 text-blue-950"
                          : "text-gray-400"
                      } py-1 ps-2 rounded-lg text-label-sm block`}
                      onClick={() => menuItemHandle()}
                    >
                      {menuItem.title}
                    </Link>
                  </li>
                ))}
              </div>
            ))}
          </ul>
        </div>

        <div className="max-w-[784px] w-full mx-auto px-6">
          <PageBreadcrumb items={breadcrumb} />

          <div className="text-h1 mb-4">{title}</div>

          <p className="text-p-md text-gray-600 mb-7">{desc}</p>

          {children}
        </div>
      </div>
    </>
  );
};

export default DocumentationLayout;

const Input = {
  algorithm: true,
  fontSizeIcon: 16,
  fontSizeLG: 16,
  fontSize: 14,
  fontSizeSM: 12,
  paddingBlockLG: 7,
  paddingInlineLG: 16,
  paddingBlock: 5,
  paddingInline: 16,
  paddingBlockSM: 3,
  paddingInlineSM: 16,
  borderRadiusLG: 8,
  borderRadius: 8,
  borderRadiusSM: 8,
  colorText: "#002748",
  colorTextPlaceholder: "#7B8B95",
  colorPrimary: "#002748",
  colorBorder: "#E4E8E9",
  colorBgBase: "#fff",
  hoverBorderColor: "#002748",
  activeBorderColor: "#002748",
};

export default Input;

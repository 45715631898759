import React from "react";

import { Tabs, Radio } from "antd";

import { base } from "./code";

import radioAnatomyImg from "assets/img/documentation/radio/radio-anatomy.png";
import radioSpecsImg from "assets/img/documentation/radio/radio-specs.png";

import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

export default function DocumentationRadio() {
  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <div className="flex items-center gap-2">
                <Radio />
                <Radio checked />
                <Radio disabled />
                <Radio disabled checked />
              </div>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={<img src={radioAnatomyImg} alt="Anatomy"></img>}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={radioSpecsImg}
            column={true}
            title="Default"
            list={[
              {
                text: "Direction",
                value: "Horizontal",
              },
              {
                text: "Alignment",
                value: "Top Left",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Hug",
              },
              {
                text: "Item Spacing",
                value: 8,
              },
              {
                text: "Padding",
                value: 8,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

import { Link } from "react-router-dom";

import { Button } from "antd";

import CardItem from "./CardItem";

function CardItemSection({ title }) {
  const isMobile = window.innerWidth <= 767;

  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <span className="text-accent text-h6 md:text-h3 font-medium">
          {title}
        </span>

        <Button size={isMobile ? "small" : "large"} shape="round">
          <Link to="/product-list">Hepsini Gör</Link>
        </Button>
      </div>

      <div className="grid grid-cols-2 xl:grid-cols-4 gap-4 md:gap-6">
        <CardItem
          imgColor="#A578D2"
          subTitle="Hypeople"
          title="Compound AI"
          text="Sürdürülebilir müşteri memnuniyeti ancak mümkündür."
        />

        <CardItem
          imgColor="#37BE7E"
          subTitle="Hypeople"
          title="Flux Financial"
          text="Sürdürülebilir müşteri memnuniyeti ancak mümkündür."
          newTag={true}
        />

        <CardItem
          imgColor="#16465A"
          subTitle="Hypeople"
          title="Merchantfy"
          text="Sürdürülebilir müşteri memnuniyeti ancak mümkündür."
        />

        <CardItem
          imgColor="#E9906B"
          subTitle="Hypeople"
          title="Compound AI"
          text="Sürdürülebilir müşteri memnuniyeti ancak mümkündür."
        />
      </div>
    </div>
  );
}

export default CardItemSection;

import React from "react";

import { Button, Col, Row } from "antd";
import { Flash } from "iconoir-react";

export default function ButtonPage() {
  return (
    <div className="m-12">
      <Row gutter={[24, 32]} align="middle">
        <Col span={24}>
          <h1 className="text-5xl font-bold">Buttons</h1>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button type="primary" size="large" icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button type="primary" icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button type="primary" size="small" icon={<Flash />}>
                Label
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button type="primary" size="large" disabled icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button type="primary" disabled icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button type="primary" size="small" disabled icon={<Flash />}>
                Label
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button
                type="primary"
                size="large"
                shape="circle"
                icon={<Flash />}
              />
            </Col>

            <Col>
              <Button type="primary" shape="circle" icon={<Flash />} />
            </Col>

            <Col>
              <Button
                type="primary"
                size="small"
                shape="circle"
                icon={<Flash />}
              />
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="primary"
                size="large"
                shape="circle"
                disabled
                icon={<Flash />}
              />
            </Col>

            <Col>
              <Button type="primary" shape="circle" disabled icon={<Flash />} />
            </Col>

            <Col>
              <Button
                type="primary"
                size="small"
                shape="circle"
                disabled
                icon={<Flash />}
              />
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button type="primary" size="large" icon={<Flash />} />
            </Col>

            <Col>
              <Button type="primary" icon={<Flash />} />
            </Col>

            <Col>
              <Button type="primary" size="small" icon={<Flash />} />
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button type="primary" size="large" disabled icon={<Flash />} />
            </Col>

            <Col>
              <Button type="primary" disabled icon={<Flash />} />
            </Col>

            <Col>
              <Button type="primary" size="small" disabled icon={<Flash />} />
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button
                type="primary"
                size="large"
                shape="round"
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>

            <Col>
              <Button type="primary" shape="round" icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                size="small"
                shape="round"
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="primary"
                size="large"
                shape="round"
                disabled
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>

            <Col>
              <Button type="primary" shape="round" disabled icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                size="small"
                shape="round"
                disabled
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button type="primary" size="large" shape="circle">
                01
              </Button>
            </Col>

            <Col>
              <Button type="primary" shape="circle">
                01
              </Button>
            </Col>

            <Col>
              <Button type="primary" size="small" shape="circle">
                01
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button type="primary" size="large" shape="circle" disabled>
                01
              </Button>
            </Col>

            <Col>
              <Button type="primary" shape="circle" disabled>
                01
              </Button>
            </Col>

            <Col>
              <Button type="primary" size="small" shape="circle" disabled>
                01
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button type="primary" size="large" className="btn-only-text">
                01
              </Button>
            </Col>

            <Col>
              <Button type="primary" className="btn-only-text">
                01
              </Button>
            </Col>

            <Col>
              <Button type="primary" size="small" className="btn-only-text">
                01
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="primary"
                size="large"
                disabled
                className="btn-only-text"
              >
                01
              </Button>
            </Col>

            <Col>
              <Button type="primary" disabled className="btn-only-text">
                01
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                size="small"
                disabled
                className="btn-only-text"
              >
                01
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <div className="border"></div>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button size="large" icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button icon={<Flash />}>Label</Button>
            </Col>

            <Col>
              <Button size="small" icon={<Flash />}>
                Label
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button size="large" disabled icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button disabled icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button size="small" disabled icon={<Flash />}>
                Label
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button size="large" shape="circle" icon={<Flash />} />
            </Col>

            <Col>
              <Button shape="circle" icon={<Flash />} />
            </Col>

            <Col>
              <Button size="small" shape="circle" icon={<Flash />} />
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button size="large" shape="circle" disabled icon={<Flash />} />
            </Col>

            <Col>
              <Button shape="circle" disabled icon={<Flash />} />
            </Col>

            <Col>
              <Button size="small" shape="circle" disabled icon={<Flash />} />
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button size="large" icon={<Flash />} />
            </Col>

            <Col>
              <Button icon={<Flash />} />
            </Col>

            <Col>
              <Button size="small" icon={<Flash />} />
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button size="large" disabled icon={<Flash />} />
            </Col>

            <Col>
              <Button disabled icon={<Flash />} />
            </Col>

            <Col>
              <Button size="small" disabled icon={<Flash />} />
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button size="large" shape="round" icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button shape="round" icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button size="small" shape="round" icon={<Flash />}>
                Label
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button size="large" shape="round" disabled icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button shape="round" disabled icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button size="small" shape="round" disabled icon={<Flash />}>
                Label
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button size="large" shape="circle">
                01
              </Button>
            </Col>

            <Col>
              <Button shape="circle">01</Button>
            </Col>

            <Col>
              <Button size="small" shape="circle">
                01
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button size="large" shape="circle" disabled>
                01
              </Button>
            </Col>

            <Col>
              <Button shape="circle" disabled>
                01
              </Button>
            </Col>

            <Col>
              <Button size="small" shape="circle" disabled>
                01
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button size="large" className="btn-only-text">
                01
              </Button>
            </Col>

            <Col>
              <Button className="btn-only-text">01</Button>
            </Col>

            <Col>
              <Button size="small" className="btn-only-text">
                01
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button size="large" disabled className="btn-only-text">
                01
              </Button>
            </Col>

            <Col>
              <Button disabled className="btn-only-text">
                01
              </Button>
            </Col>

            <Col>
              <Button size="small" disabled className="btn-only-text">
                01
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <div className="border"></div>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button type="text" size="large" icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button type="text" icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button type="text" size="small" icon={<Flash />}>
                Label
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button type="text" size="large" disabled icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button type="text" disabled icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button type="text" size="small" disabled icon={<Flash />}>
                Label
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button
                type="text"
                size="large"
                shape="circle"
                icon={<Flash />}
              />
            </Col>

            <Col>
              <Button type="text" shape="circle" icon={<Flash />} />
            </Col>

            <Col>
              <Button
                type="text"
                size="small"
                shape="circle"
                icon={<Flash />}
              />
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="text"
                size="large"
                shape="circle"
                disabled
                icon={<Flash />}
              />
            </Col>

            <Col>
              <Button type="text" shape="circle" disabled icon={<Flash />} />
            </Col>

            <Col>
              <Button
                type="text"
                size="small"
                shape="circle"
                disabled
                icon={<Flash />}
              />
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button type="text" size="large" icon={<Flash />} />
            </Col>

            <Col>
              <Button type="text" icon={<Flash />} />
            </Col>

            <Col>
              <Button type="text" size="small" icon={<Flash />} />
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button type="text" size="large" disabled icon={<Flash />} />
            </Col>

            <Col>
              <Button type="text" disabled icon={<Flash />} />
            </Col>

            <Col>
              <Button type="text" size="small" disabled icon={<Flash />} />
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button type="text" size="large" shape="round" icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button type="text" shape="round" icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button type="text" size="small" shape="round" icon={<Flash />}>
                Label
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="text"
                size="large"
                shape="round"
                disabled
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>

            <Col>
              <Button type="text" shape="round" disabled icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button
                type="text"
                size="small"
                shape="round"
                disabled
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button type="text" size="large" shape="circle">
                01
              </Button>
            </Col>

            <Col>
              <Button type="text" shape="circle">
                01
              </Button>
            </Col>

            <Col>
              <Button type="text" size="small" shape="circle">
                01
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button type="text" size="large" shape="circle" disabled>
                01
              </Button>
            </Col>

            <Col>
              <Button type="text" shape="circle" disabled>
                01
              </Button>
            </Col>

            <Col>
              <Button type="text" size="small" shape="circle" disabled>
                01
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button type="text" size="large" className="btn-only-text">
                01
              </Button>
            </Col>

            <Col>
              <Button type="text" className="btn-only-text">
                01
              </Button>
            </Col>

            <Col>
              <Button type="text" size="small" className="btn-only-text">
                01
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="text"
                size="large"
                disabled
                className="btn-only-text"
              >
                01
              </Button>
            </Col>

            <Col>
              <Button type="text" disabled className="btn-only-text">
                01
              </Button>
            </Col>

            <Col>
              <Button
                type="text"
                size="small"
                disabled
                className="btn-only-text"
              >
                01
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <div className="border"></div>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button type="primary" danger size="large" icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button type="primary" danger icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button type="primary" danger size="small" icon={<Flash />}>
                Label
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="primary"
                danger
                size="large"
                disabled
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>

            <Col>
              <Button type="primary" danger disabled icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                danger
                size="small"
                disabled
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button
                type="primary"
                danger
                size="large"
                shape="circle"
                icon={<Flash />}
              />
            </Col>

            <Col>
              <Button type="primary" danger shape="circle" icon={<Flash />} />
            </Col>

            <Col>
              <Button
                type="primary"
                danger
                size="small"
                shape="circle"
                icon={<Flash />}
              />
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="primary"
                danger
                size="large"
                shape="circle"
                disabled
                icon={<Flash />}
              />
            </Col>

            <Col>
              <Button
                type="primary"
                danger
                shape="circle"
                disabled
                icon={<Flash />}
              />
            </Col>

            <Col>
              <Button
                type="primary"
                danger
                size="small"
                shape="circle"
                disabled
                icon={<Flash />}
              />
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button type="primary" danger size="large" icon={<Flash />} />
            </Col>

            <Col>
              <Button type="primary" danger icon={<Flash />} />
            </Col>

            <Col>
              <Button type="primary" danger size="small" icon={<Flash />} />
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="primary"
                danger
                size="large"
                disabled
                icon={<Flash />}
              />
            </Col>

            <Col>
              <Button type="primary" danger disabled icon={<Flash />} />
            </Col>

            <Col>
              <Button
                type="primary"
                danger
                size="small"
                disabled
                icon={<Flash />}
              />
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button
                type="primary"
                danger
                size="large"
                shape="round"
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>

            <Col>
              <Button type="primary" danger shape="round" icon={<Flash />}>
                Label
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                danger
                size="small"
                shape="round"
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="primary"
                danger
                size="large"
                shape="round"
                disabled
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                danger
                shape="round"
                disabled
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                danger
                size="small"
                shape="round"
                disabled
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button type="primary" danger size="large" shape="circle">
                01
              </Button>
            </Col>

            <Col>
              <Button type="primary" danger shape="circle">
                01
              </Button>
            </Col>

            <Col>
              <Button type="primary" danger size="small" shape="circle">
                01
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="primary"
                danger
                size="large"
                shape="circle"
                disabled
              >
                01
              </Button>
            </Col>

            <Col>
              <Button type="primary" danger shape="circle" disabled>
                01
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                danger
                size="small"
                shape="circle"
                disabled
              >
                01
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button
                type="primary"
                danger
                size="large"
                className="btn-only-text"
              >
                01
              </Button>
            </Col>

            <Col>
              <Button type="primary" danger className="btn-only-text">
                01
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                danger
                size="small"
                className="btn-only-text"
              >
                01
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="primary"
                danger
                size="large"
                disabled
                className="btn-only-text"
              >
                01
              </Button>
            </Col>

            <Col>
              <Button type="primary" danger disabled className="btn-only-text">
                01
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                danger
                size="small"
                disabled
                className="btn-only-text"
              >
                01
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <div className="border"></div>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="large"
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="small"
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="large"
                disabled
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                disabled
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="small"
                disabled
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="large"
                shape="circle"
                icon={<Flash />}
              />
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                shape="circle"
                icon={<Flash />}
              />
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="small"
                shape="circle"
                icon={<Flash />}
              />
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="large"
                shape="circle"
                disabled
                icon={<Flash />}
              />
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                shape="circle"
                disabled
                icon={<Flash />}
              />
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="small"
                shape="circle"
                disabled
                icon={<Flash />}
              />
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="large"
                icon={<Flash />}
              />
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                icon={<Flash />}
              />
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="small"
                icon={<Flash />}
              />
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="large"
                disabled
                icon={<Flash />}
              />
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                disabled
                icon={<Flash />}
              />
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="small"
                disabled
                icon={<Flash />}
              />
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="large"
                shape="round"
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                shape="round"
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="small"
                shape="round"
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="large"
                shape="round"
                disabled
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                shape="round"
                disabled
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="small"
                shape="round"
                disabled
                icon={<Flash />}
              >
                Label
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="large"
                shape="circle"
              >
                01
              </Button>
            </Col>

            <Col>
              <Button type="primary" className="ant-btn-success" shape="circle">
                01
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="small"
                shape="circle"
              >
                01
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="large"
                shape="circle"
                disabled
              >
                01
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                shape="circle"
                disabled
              >
                01
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                className="ant-btn-success"
                size="small"
                shape="circle"
                disabled
              >
                01
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={8}>
          <Row gutter={[24]} align="middle">
            <Col>
              <Button
                type="primary"
                size="large"
                className="btn-only-text ant-btn-success"
              >
                01
              </Button>
            </Col>

            <Col>
              <Button type="primary" className="btn-only-text ant-btn-success">
                01
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                size="small"
                className="btn-only-text ant-btn-success"
              >
                01
              </Button>
            </Col>
          </Row>

          <Row gutter={[24]} align="middle" className="mt-3">
            <Col>
              <Button
                type="primary"
                size="large"
                disabled
                className="btn-only-text ant-btn-success"
              >
                01
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                disabled
                className="btn-only-text ant-btn-success"
              >
                01
              </Button>
            </Col>

            <Col>
              <Button
                type="primary"
                size="small"
                disabled
                className="btn-only-text ant-btn-success"
              >
                01
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

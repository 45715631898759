import React, { useState } from "react";

import { Button, Modal, Tabs } from "antd";

import anatomy from "assets/img/documentation/modal/modal-anatomy.svg";
import specs from "assets/img/documentation/modal/modal-specs.svg";

import { base } from "./code";
import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

export default function DocumentationModal() {
  const [modalOpen, setModalOpen] = useState(false);

  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <>
                <Button type="primary" onClick={() => setModalOpen(true)}>
                  Open Modal
                </Button>

                <Modal
                  centered
                  closable={false}
                  open={modalOpen}
                  onOk={() => setModalOpen(false)}
                  onCancel={() => setModalOpen(false)}
                  className="modal-content-padding"
                >
                  <div className="h-[240px] bg-blue-600"></div>

                  <div className="p-6">
                    <h5 className="text-h4 font-normal text-primaryA mb-2">
                      Title
                    </h5>

                    <p className="text-p-md font-normal text-gray-600">
                      Proin ut dui sed metus pharetra hend rerit vel non mi.
                      Nulla ornare faucibus ex, non facilisis nisl. Maecenas
                      aliquet mauris ut tempus Proin ut dui sed metus pharetra
                      hend rerit vel non mi.
                    </p>
                  </div>
                </Modal>
              </>
            }
          />
          <DocumentationCard
            title="Anatomy"
            content={
              <div className="bg-gray-50 rounded-lg">
                <img src={anatomy} alt="Modal" />
              </div>
            }
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={specs}
            title="Modal Sheet"
            column={true}
            list={[
              {
                text: "Direction",
                value: "Vertical",
              },
              {
                text: "Alignment",
                value: "Bottom left",
              },
              {
                text: "Vertical resizing",
                value: "Bottom left",
              },
              {
                text: "Horizontal resizing",
                value: "Fixed",
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

import React from "react";

import { Button, ConfigProvider, Tooltip, Tabs } from "antd";

import anatomy from "assets/img/documentation/tooltip/tooltip-anatomy.svg";
import styling from "assets/img/documentation/tooltip/tooltip-styling.svg";
import specs from "assets/img/documentation/tooltip/tooltip-specs.svg";

import { base } from "./code";
import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

export default function DocumentationTooltip() {
  const text = <span>Label</span>;
  const buttonWidth = 80;

  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            content={
              <ConfigProvider
                button={{
                  style: {
                    width: buttonWidth,
                    margin: 4,
                  },
                }}
              >
                <div>
                  <div
                    style={{
                      marginInlineStart: buttonWidth,
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Tooltip placement="topLeft" title={text}>
                      <Button>TL</Button>
                    </Tooltip>
                    <Tooltip placement="top" title={text}>
                      <Button>Top</Button>
                    </Tooltip>
                    <Tooltip placement="topRight" title={text}>
                      <Button>TR</Button>
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      width: buttonWidth,
                      float: "inline-start",
                    }}
                  >
                    <Tooltip placement="leftTop" title={text}>
                      <Button>LT</Button>
                    </Tooltip>
                    <Tooltip placement="left" title={text}>
                      <Button>Left</Button>
                    </Tooltip>
                    <Tooltip placement="leftBottom" title={text}>
                      <Button>LB</Button>
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      width: buttonWidth,
                      marginInlineStart: buttonWidth * 4 + 24,
                    }}
                  >
                    <Tooltip placement="rightTop" title={text}>
                      <Button>RT</Button>
                    </Tooltip>
                    <Tooltip placement="right" title={text}>
                      <Button>Right</Button>
                    </Tooltip>
                    <Tooltip placement="rightBottom" title={text}>
                      <Button>RB</Button>
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      marginInlineStart: buttonWidth,
                      clear: "both",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Tooltip placement="bottomLeft" title={text}>
                      <Button>BL</Button>
                    </Tooltip>
                    <Tooltip placement="bottom" title={text}>
                      <Button>Bottom</Button>
                    </Tooltip>
                    <Tooltip placement="bottomRight" title={text}>
                      <Button>BR</Button>
                    </Tooltip>
                  </div>
                </div>
              </ConfigProvider>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={
              <div className="bg-gray-50 rounded-lg">
                <img src={anatomy} alt="Tooltip" />
              </div>
            }
          />

          <DocumentationCard
            title="Styling"
            content={
              <div className="bg-gray-50 rounded-lg">
                <img src={styling} alt="Tooltip" />
              </div>
            }
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={specs}
            title="Default"
            column={true}
            list={[
              {
                text: "Direction",
                value: "Horizontal",
              },
              {
                text: "Alignment",
                value: "Top left",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Hug",
              },
              {
                text: "Padding",
                value: "16",
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

export const base = `import React from "react";

import { Badge, Space } from "antd";
import { HeartSolid } from "iconoir-react";

export default function Badge() {
  return (
    <Space size="middle">
      <Badge color="#007FDF" status="processing" />

      <Badge
        count={
          <div className="bg-blue-600 rounded-full w-4 h-4 !flex items-center justify-center">
            <HeartSolid
              className="text-white"
              width={10}
              height={10}
            />
          </div>
        }
      />

      <Badge count={5} color="#007FDF" />
    </Space>
  );
}`;
const Button = {
  fontWeight: 500,
  fontSizeSM: 12,
  contentFontSizeSM: 12,
  borderRadiusLG: 8,
  borderRadius: 8,
  borderRadiusSM: 8,
  paddingBlockLG: 10,
  paddingInlineLG: 16,
  paddingBlock: 10,
  paddingInline: 14,
  paddingBlockSM: 0,
  paddingInlineSM: 10,
  lineHeight: 40,
  controlHeight: 36,
  controlHeightSM: 32,
  primaryShadow: "none",
  colorText: "#333D49",
  colorBorder: "#E4E8E9",
  colorPrimaryHover: "#1A3D5A",
  borderColorDisabled: "#E4E8E9",
  colorBgContainerDisabled: "#E4E8E9",
  colorTextDisabled: "#A7B3B9",
};

export default Button;

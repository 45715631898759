const Steps = {
  iconSize: 24,
  iconFontSize: 12,
  colorPrimary: "#007FDF",
  fontSizeLG: 14,
  colorText: "#0D0F11",
  colorFillContent: "#E4E8E9",
  colorSplit: "#CBD3D6",
  colorTextDescription: "#A7B3B9",
  colorTextLabel: "#A7B3B9",
};

export default Steps;

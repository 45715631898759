import { useState } from "react";
import { Button, Col, Form, Input, Row } from "antd";

import logo from "assets/img/logo.svg";
import bg from "assets/img/login-bg.svg";

import { Eye, EyeClosed } from 'iconoir-react';

function Login() {
  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");

  const handleInputChange = (e, setInputValue) => {
    setInputValue(e.target.value);
  };

  const validateTCKN = (rule, value, callback) => {
    if (value) {
      if (value.length === 11) {
        const tckn = value.toString();
        const oddSum =
          parseInt(tckn.charAt(0)) +
          parseInt(tckn.charAt(2)) +
          parseInt(tckn.charAt(4)) +
          parseInt(tckn.charAt(6)) +
          parseInt(tckn.charAt(8));
        const evenSum =
          parseInt(tckn.charAt(1)) +
          parseInt(tckn.charAt(3)) +
          parseInt(tckn.charAt(5)) +
          parseInt(tckn.charAt(7));
        const tenthDigit = (oddSum * 7 - evenSum) % 10;
        const eleventhDigit =
          (oddSum + evenSum + parseInt(tckn.charAt(9))) % 10;

        if (
          parseInt(tckn.charAt(10)) === eleventhDigit &&
          parseInt(tckn.charAt(9)) === tenthDigit
        ) {
          callback();
        } else {
          callback("Geçersiz TC Kimlik Numarası");
        }
      } else {
        callback("Lütfen 11 haneli bir TC Kimlik Numarası girin");
      }
    } else {
      callback("TC Kimlik Numarası boş bırakılamaz");
    }
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="h-screen">
      <Row className="h-full p-4">
        <Col span={24} lg={12} className="h-full flex flex-col">
          <div className="login-logo">
            <img src={logo} alt="KKB" />
          </div>

          <div className="flex-1 flex flex-col justify-center mx-auto w-full max-w-[375px]">
            <h1 className="text-[32px] text-primaryA leading-none font-medium">
              Giriş yap
            </h1>
            <p className="text-[16px] text-gray-400 mt-4 mb-11">
              Tekrar hoşgeldiniz, lütfen bilgilerinizi giriniz.
            </p>

            <Form
              name="login"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <span className="block text-[16px] text-primaryA font-medium mb-2">
                TCKN
              </span>
              <Form.Item
                name="TCKN"
                rules={[
                  {
                    validator: validateTCKN,
                  },
                ]}
              >
                <Input
                  onChange={(e) => handleInputChange(e, setInputValue1)}
                  className={inputValue1 ? "active" : ""}
                />
              </Form.Item>

              <span className="block text-[16px] text-primaryA font-medium mb-2">
                Şifre
              </span>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Lütfen şifre giriniz!",
                  },
                ]}
              >
                <Input.Password
                  onChange={(e) => handleInputChange(e, setInputValue2)}
                  className={inputValue2 ? "active" : ""}
                  iconRender={(visible) =>
                    visible ? (
                      <span>
                        <Eye width={24} height={24} />
                      </span>
                    ) : (
                      <span>
                        <EyeClosed width={24} height={24} />
                      </span>
                    )
                  }
                />
              </Form.Item>

              <Form.Item>
                <Button
                  href="/"
                  htmlType="submit"
                  type="primary"
                  block
                  className="mt-5"
                >
                  Giriş Yap
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>

        <Col span={24} lg={12} className="hidden lg:block h-full">
          <img
            src={bg}
            alt="Login"
            className="w-full h-full object-cover object-top rounded-2xl"
          />

          <div className="absolute bottom-[100px] left-0 right-0 px-1 max-w-[540px] mx-auto text-center">
            <span className="text-[36px] font-semibold text-white">
              Lorem ipsum dolor sit amet
            </span>
            <p className="text-p-md text-gray-200 mt-2">
              Lorem ipsum dolor sit amet consectetur. Ultricies volutpat feugiat
              fermentum semper fringilla. Sapien pretium ut nisl arcu.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Login;

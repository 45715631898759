import React, { useState } from "react";

import { Tabs, Form, Input } from "antd";
import { CheckCircleSolid, WarningCircleSolid } from "iconoir-react";

import { base, handle } from "./code";

import inputAnatomyImg from "assets/img/documentation/input/input-anatomy.png";
import inputSpecsImg from "assets/img/documentation/input/input-specs.png";

import DocumentationCard from "components/DocumentationCard";
import DocumentationSpecsCard from "components/DocumentationSpecsCard";

export default function DocumentationInput() {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e, setInputValue) => {
    setInputValue(e.target.value);
  };

  const items = [
    {
      key: "1",
      label: "Overview",
      children: (
        <div className="mt-10">
          <DocumentationCard
            code={base}
            column={true}
            content={
              <div className="w-[32.5rem]">
                <Input
                  size="large"
                  placeholder="Placeholder"
                  onChange={(e) => handleInputChange(e, setInputValue)}
                  className={inputValue ? "active" : ""}
                />
              </div>
            }
          />

          <DocumentationCard
            title="Anatomy"
            content={<img src={inputAnatomyImg} alt="Anatomy"></img>}
          />

          <DocumentationCard
            column={true}
            code={handle}
            title="Handle"
            content={
              <div className="flex flex-col gap-12 w-[32.5rem]">
                <Input placeholder="Enable" size="large" />

                <Input
                  className="active"
                  defaultValue="Active"
                  placeholder="Active"
                  allowClear
                  size="large"
                />

                <Form.Item className="mb-0" validateStatus="error">
                  <Input
                    placeholder="Error"
                    defaultValue="Error"
                    size="large"
                    allowClear
                    suffix={
                      <WarningCircleSolid
                        width={20}
                        height={20}
                        className="text-negative"
                      />
                    }
                  />
                </Form.Item>

                <Form.Item className="mb-0" validateStatus="success">
                  <Input
                    placeholder="Success"
                    defaultValue="Success"
                    size="large"
                    allowClear
                    suffix={
                      <CheckCircleSolid
                        width={20}
                        height={20}
                        className="text-positive"
                      />
                    }
                  />
                </Form.Item>

                <Form.Item
                  className="mb-0"
                  hasFeedback
                  validateStatus="validating"
                >
                  <Input
                    size="large"
                    placeholder="Loading"
                    defaultValue="Loading"
                  />
                </Form.Item>

                <Input size="large" readOnly placeholder="Read Only" />

                <Input size="large" disabled placeholder="Disable" />
              </div>
            }
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Specs",
      children: (
        <div className="mt-10">
          <DocumentationSpecsCard
            img={inputSpecsImg}
            column={true}
            title="Default"
            list={[
              {
                text: "Direction",
                value: "Horizontal",
              },
              {
                text: "Alignment",
                value: "Middle Left",
              },
              {
                text: "Vertical resizing",
                value: "Hug",
              },
              {
                text: "Horizontal resizing",
                value: "Fixed",
              },
              {
                text: "Padding left",
                value: 16,
              },
              {
                text: "Padding right",
                value: 16,
              },
              {
                text: "Padding top",
                value: 8,
              },
              {
                text: "Padding bottom",
                value: 8,
              },
            ]}
          />
        </div>
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
}

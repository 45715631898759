export const menu = [
    {
        title: "General",
        list: [
            {
                title: "Button",
                url: "/documentation/button",
            },
            {
                title: "Icon",
                url: "/documentation/icons",
            },
            {
                title: "Typography",
                url: "/documentation/typography",
            },
        ]
    },
    {
        title: "Navigation",
        list: [
            {
                title: "Breadcrumb",
                url: "/documentation/breadcrumb",
            },
            {
                title: "Menu",
                url: "/documentation/menu",
            },
            {
                title: "Pagination",
                url: "/documentation/pagination",
            },
            {
                title: "Steps",
                url: "/documentation/steps",
            },
        ]
    },
    {
        title: "Data Entry",
        list: [
            {
                title: "Checkbox",
                url: "/documentation/checkbox",
            },
            {
                title: "Date Picker",
                url: "/documentation/date-picker",
            },
            {
                title: "Form",
                url: "/documentation/form",
            },
            {
                title: "Input",
                url: "/documentation/input",
            },
            {
                title: "Input Number",
                url: "/documentation/input-number",
            },
            {
                title: "Radio",
                url: "/documentation/radio",
            },
            {
                title: "Select",
                url: "/documentation/select",
            },
            {
                title: "Slider",
                url: "/documentation/slider",
            },
            {
                title: "Switch",
                url: "/documentation/switch",
            },
            {
                title: "Time Picker",
                url: "/documentation/time-picker",
            },
            {
                title: "Upload",
                url: "/documentation/upload",
            },
        ]
    },
    {
        title: "Data Display",
        list: [
            {
                title: "Avatar",
                url: "/documentation/avatar",
            },
            {
                title: "Badge",
                url: "/documentation/badge",
            },
            {
                title: "Calendar",
                url: "/documentation/calendar",
            },
            {
                title: "Collapse",
                url: "/documentation/collapse",
            },
            {
                title: "Tabs",
                url: "/documentation/tabs",
            },
            {
                title: "Tag",
                url: "/documentation/tag",
            },
            {
                title: "Tooltip",
                url: "/documentation/tooltip",
            },
        ]
    },
    {
        title: "Feedback",
        list: [
            {
                title: "Alert",
                url: "/documentation/alert",
            },
            {
                title: "Message",
                url: "/documentation/message",
            },
            {
                title: "Skelaton",
                url: "/documentation/skelaton",
            },
            {
                title: "Modal",
                url: "/documentation/modal",
            },
            {
                title: "Result",
                url: "/documentation/result",
            },
        ]
    }
] 
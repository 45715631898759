export const base = `import React from "react";

import { Button, ConfigProvider, Tooltip } from "antd";

export default function Tooltip() {
  const text = <span>Label</span>;
  const buttonWidth = 80;
  
  return (
    <ConfigProvider
      button={{
        style: {
          width: buttonWidth,
          margin: 4,
        },
      }}
    >
      <div>
        <div
          style={{
            marginInlineStart: buttonWidth,
            whiteSpace: "nowrap",
          }}
        >
          <Tooltip placement="topLeft" title={text}>
            <Button>TL</Button>
          </Tooltip>
          <Tooltip placement="top" title={text}>
            <Button>Top</Button>
          </Tooltip>
          <Tooltip placement="topRight" title={text}>
            <Button>TR</Button>
          </Tooltip>
        </div>
        <div
          style={{
            width: buttonWidth,
            float: "inline-start",
          }}
        >
          <Tooltip placement="leftTop" title={text}>
            <Button>LT</Button>
          </Tooltip>
          <Tooltip placement="left" title={text}>
            <Button>Left</Button>
          </Tooltip>
          <Tooltip placement="leftBottom" title={text}>
            <Button>LB</Button>
          </Tooltip>
        </div>
        <div
          style={{
            width: buttonWidth,
            marginInlineStart: buttonWidth * 4 + 24,
          }}
        >
          <Tooltip placement="rightTop" title={text}>
            <Button>RT</Button>
          </Tooltip>
          <Tooltip placement="right" title={text}>
            <Button>Right</Button>
          </Tooltip>
          <Tooltip placement="rightBottom" title={text}>
            <Button>RB</Button>
          </Tooltip>
        </div>
        <div
          style={{
            marginInlineStart: buttonWidth,
            clear: "both",
            whiteSpace: "nowrap",
          }}
        >
          <Tooltip placement="bottomLeft" title={text}>
            <Button>BL</Button>
          </Tooltip>
          <Tooltip placement="bottom" title={text}>
            <Button>Bottom</Button>
          </Tooltip>
          <Tooltip placement="bottomRight" title={text}>
            <Button>BR</Button>
          </Tooltip>
        </div>
      </div>
    </ConfigProvider>
  );
}`;
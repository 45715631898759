import React from "react";

import { Breadcrumb } from "antd";
import { NavArrowRight } from "iconoir-react";

export default function PageBreadcrumb({ items }) {
  return (
    <div className="my-6">
      <Breadcrumb separator={<NavArrowRight className="mt-0.5" width={18} height={18} />} items={items} />
    </div>
  );
}
